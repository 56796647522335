import React, { useEffect, useState } from 'react';

import translation from "../Helpers/translation";
import { fade, unFade } from "../Helpers/fade";

import styles from './input.module.scss'

function Inputs(props) {

    const [types, setTypes] = useState(
        [
            {"name" : "listy", "value" : 1, "checked" : false},
            {"name" : "rekopisy", "value" : 2, "checked" : false},
            {"name" : "afisze-i-plakaty", "value" : 3, "checked" : false},
            {"name" : "fotografie-muzealne", "value" : 4, "checked" : false},
            {"name" : "grafika-muzealna", "value" : 5, "checked" : false},
            {"name" : "obrazy-muzealne", "value" : 6, "checked" : false},
            {"name" : "pocztowki-muzealne", "value" : 7, "checked" : false},
            {"name" : "archiwa-muzealne", "value" : 8, "checked" : false},
            {"name" : "rzemioslo-muzealne", "value" : 9, "checked" : false},
            {"name" : "zbiory-fotograficzne", "value" : 10, "checked" : false},
            {"name" : "egzemplarz-ksiazki", "value" : 11, "checked" : false},
            {"name" : "rzezby-muzealne", "value" : 12, "checked" : false},
            {"name" : "muzeum-inne", "value" : 13, "checked" : false},
            {"name" : "druki-muzyczne", "value" : 14, "checked" : false},
            {"name" : "audio", "value" : 16, "checked" : false},
            {"name" : "video", "value" : 17, "checked" : false},
            {"name" : "czasopisma", "value" : 18, "checked" : false},
        ]
    )
    const [update, setUpdate] = useState(true)

    const clickHandler = (event) => {
        props.update(event);
        setUpdate(true);
    }
    
    useEffect(() => {
        if(update) {
            const oldTypes = types;
            for(let i = 0; i < oldTypes.length; i++) {
                let set = false
                for (let j = 0; j < props.checked.length; j++) {
                    if(props.checked[j] === oldTypes[i].value.toString()) {
                        set = true
                    }
                }
                oldTypes[i].checked = set
            }
            setTypes(oldTypes)
            setUpdate(false);
        }
        
    },[props, props.checked, types, update])

    useEffect(() => {
        if(!props.wrapped) {
            for(let i = 0; i < types.length; i++) {
                unFade(i, 1,"grid",3)
            }
            unFade("inputsWrapper", 1,"grid")
            unFade("buttonsWrapper", 1,"flex")
        } else {
            fade("inputsWrapper")
            fade("buttonsWrapper")
            for(let i = 0; i < types.length; i++) {
                fade(i,3)
            }
        }
    },[props.wrapped, types.length])

    return (

        <>
            <div id={"inputsWrapper"} className={styles.inputsWrapper}>
                {types.map((item, index) => {
                    return (
                        <div key={index} id={index} className={styles.inputWrapper}>
                            <label className={styles.check}>
                                <input type={"checkbox"} name={item.name} value={item.value} onClick={(event => clickHandler(event))} defaultChecked={item.checked}/>
                                <div className={item.checked ? styles.boxChecked : styles.boxUnChecked}/>
                            </label>
                            <span className={styles.checkboxText}>{translation.typeDescription[item.name]}</span>
                        </div>
                    )
                })}
            </div>
            <div id={"buttonsWrapper"} className={styles.buttonsWrapper}>
                <div className={styles.clearButton} onClick={props.clear}>{translation.clearButton}</div>
                <div className={styles.searchButton} onClick={props.search}>{translation.searchButton}</div>
            </div>
        </>
    );
}

export default Inputs;