import React , { useState, useEffect } from 'react';
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";

import translation from "../../Components/Helpers/translation";
import List from "../../Components/List/list";

import styles from './listWrapper.module.scss';
import { FadeOut, Loader } from "../../Components/Loader/loader";
import Inputs from "../../Components/Inputs/inputs";
	import headerStyles from "../Header/header.module.scss";
import queryString from "query-string";

function ListWrapper(props) {

	const [lang, ] = useState(window.location.href.split('/')[3]);
	const history = useHistory();
	let { id } = useParams();
	id = parseInt(id);

	const [data, setDate] = useState(null);
	const [dataTop, setDateTop] = useState(null);
	const [keyword, setKeyword] = useState()
	const [wrapped, setWrapped] = useState(true)
	const [searchParams, setSearchParams] = useState([])
	const [more, setMore] = useState(true)
	const [offset, ] = useState(20);
	const [scrollPosition, setScrollPosition] = useState(window.scrollY)
	const [scrollHeight, setScrollHeight] = useState(0)
	const [scrollPercent, setScrollPercent] =  useState(0)
	const [counter, setCounter] = useState(0)
	const [imagesNull, setImagesNull] = useState([])
	const [historyOffset, setHistoryOffset] = useState(sessionStorage.getItem("offset") ? parseInt(sessionStorage.getItem("offset")) :  20)
	const [link, setLink] = useState(`${props.url}index.php/groups/get_items_by_id_group/${id}?limit=${historyOffset}&offset=0&lang=${lang}`)

	const handleScroll = () => {
		setTimeout(() => {
			setScrollPosition(window.scrollY)
		}, 1000);
	}

	const updateSearchParams = (event) => {
		const oldSearchParams = searchParams;
		let push = true;
		for( let i = 0; i < oldSearchParams.length; i++){
			if ( oldSearchParams[i] === event.target.value) {
				push = false
			}
		}
		if (push) {
			oldSearchParams.push(event.target.value)
		} else {
			const oldSearchParams = searchParams;
			for( let i = 0; i < oldSearchParams.length; i++){
				if ( oldSearchParams[i] === event.target.value) {
					oldSearchParams.splice(i, 1);
				}
			}
			setSearchParams(oldSearchParams)
		}
	}

	const updateKeyWord = (e) => {
		setKeyword(e.target.value)
	}

	useEffect(() => {
		if(counter === 0) {
			if(historyOffset > 20) {
				setCounter(historyOffset / 20 )
			} else {
				setCounter(1)
			}
			axios
				.post(link)
				.then(res => {
					setDate(res.data);
					FadeOut("loader");
					setMore(res.data.length === historyOffset)
					if(res.data.length === historyOffset) {
						if(historyOffset > 20) {
							setLink(`${props.url}index.php/groups/get_items_by_id_group/${id}?limit=${historyOffset}&offset=${offset}&lang=${lang}`)
							sessionStorage.removeItem("offset")
						} else {
							setLink(`${props.url}index.php/groups/get_items_by_id_group/${id}?limit=${offset}&offset=${offset}&lang=${lang}`)
							sessionStorage.removeItem("offset")
						}
					}
					setScrollHeight((document.documentElement.scrollHeight - window.innerHeight))
					let nullCounter = 0
					for (const item in res.data) {
						if(res.data[item].image === null) {
							nullCounter++
						}
					}
					setImagesNull(nullCounter)
				})
			axios
				.post(props.url + "index.php/groups/get_by_id/" + id)
				.then(res => {
					setDateTop(res.data)
				})
		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	},[counter, historyOffset, id, lang, link, offset, props.url])

	const toQueryUrl = () => {
		let query = ''
		if(keyword) {
			query = `keyword=${keyword}`
		} else {
			query = `keyword=`
		}
		if(searchParams.length) {
			query = query + `&${queryString.stringify({types: searchParams}, {arrayFormat: 'bracket'})}`
		} else {
			query = query +  `&types[]=`
		}
		return query
	}

	const search = () => {
		history.push(`/${lang}/wyszukanie/values?${toQueryUrl()}`)
		window.location.reload(false);
	}

	const clear = () => {
		history.push(`/${lang}/wyszukanie/values?keyword=&types[]=`)
		window.location.reload(false);
	}

	const searchOnKeyEnter = (e) => {
		if (e.keyCode === 13 && keyword) {
			history.push(`/${lang}/wyszukanie/values?${toQueryUrl()}`)
			window.location.reload(false);
		}
	}

	useEffect(() => {
		let header = document.getElementById("header")
		header.classList.add(headerStyles.header)
		if (!wrapped) {
			if(header.classList.contains(headerStyles.header)) {
				header.classList.remove(headerStyles.header)
			} else {
				header.classList.add(headerStyles.header)
			}
		}
	},[wrapped])

	useEffect(() => {
		if((scrollPercent >= 70 && more && counter > 0 && data ) || (imagesNull  > 10)) {
			setMore(false)
			setImagesNull(0)
			axios
				.post(link)
				.then(res => {
					let oldData = data
					oldData = [...oldData, ...res.data]
					setDate(oldData);
					setCounter(counter + 1)
					setLink(`${props.url}index.php/groups/get_items_by_id_group/${id}?limit=${offset}&offset=${offset * (counter + 1)}&lang=${lang}`)
					if(res.data.length === offset) {
						setMore(true)
						setHistoryOffset(offset * (counter + 1))
					}
					setScrollHeight(document.documentElement.scrollHeight - window.innerHeight)
					let nullCounter = 0
					for (const item in res.data) {
						if(res.data[item].image === null) {
							nullCounter++
						}
					}
					setImagesNull(nullCounter)
				})
		}
	},[counter, data, lang, link, more, offset, props.url, scrollPercent, searchParams, id, imagesNull])

	useEffect(() => {
		let calc = parseInt((scrollPosition/scrollHeight * 100).toFixed(0))
		setScrollPercent(calc)
	},[scrollHeight, scrollPosition])

	return (
		<>
			<Loader/>
			{data ? (
				<div className={styles.wrapper}>
					<div className={`${styles.searchWrapper} ${wrapped ? '' : styles.searchWrapperOpen}`}>
						<div className={styles.inputWrapper}>
							<input type={"text"} className={`${styles.searchInput} ${wrapped ? '' : styles.searchInputOpen}`} placeholder={translation.search} onChange={updateKeyWord} onKeyUp={searchOnKeyEnter}/>
							<div className={`${styles.searchButton} ${wrapped ? '' : styles.searchButtonOpen}`} onClick={search}/>
						</div>
						<Inputs wrapped={wrapped} update={updateSearchParams} checked={searchParams} clear={clear} search={search}/>
						<span className={styles.advSearch}>{translation.advSearch}</span>
						<span className={styles.advSearchIco} onClick={() => {setWrapped(!wrapped); window.scroll(0,0);}}/>
					</div>
					<List listTitlesType={false} data={data} main={props.main} dataTop={dataTop} lang={lang} historyOffset={historyOffset + offset}/>
				</div>
			) : null}
		</>

	);
}

export default ListWrapper;

