import React from 'react';

//import translation from "../../Components/Helpers/translation";

import styles from './footer.module.scss';

import nifc_pl from '../../assets/image/footer/logo_nifc_pl.png';
import heritage_pl from '../../assets/image/footer/logo_heritage_pl.png';
import chopinFooter1_PL from '../../assets/image/footer/chopin-footer-1-pl.png';
import chopinFooter2_PL from '../../assets/image/footer/chopin-footer-2-pl.png';
import chopinFooter3_PL from '../../assets/image/footer/chopin-footer-3-pl.png';
import chopinFooter4_PL from '../../assets/image/footer/chopin-footer-4-pl.png';
import fb_ico from '../../assets/image/footer/fb_ico.png';
import twitter_ico from '../../assets/image/footer/twitter_ico.png';
import ig_ico from '../../assets/image/footer/ig_ico.png';
import yt_ico from '../../assets/image/footer/yt_ico.png';


function Footer() {

	const year = new Date().getFullYear();

	return (
		<div className={styles.wrapper}>
			<div className={styles.oval}>
				<div className={styles.socialWrapper}>
					<a className={styles.fb} target="_blank" href="https://www.facebook.com/Narodowy-Instytut-Fryderyka-Chopina-1570915943228919/" rel="noreferrer">
						<img src={fb_ico} alt="facebook" />
					</a>
					<a className={styles.tw} target="_blank" href="https://twitter.com/chopininstitute" rel="noreferrer">
						<img src={twitter_ico} alt="twitter" />
					</a>
					<a className={styles.ig} target="_blank" href="https://www.instagram.com/chopininstitute/?hl=pl" rel="noreferrer">
						<img src={ig_ico} alt="instagram" />
					</a>
					<a className={styles.yt} target="_blank" href="https://www.youtube.com/channel/UCSTXol20Q01Uj-U5Yp3IqFg" rel="noreferrer">
						<img src={yt_ico} alt="youtube" />
					</a>
				</div>
			</div>
			<div className={styles.greyWrapper}>
				<div className={styles.content}>
					<img src={heritage_pl} alt="" />
					<img src={nifc_pl} alt="" />
					<span className={styles.copyright}>
						&copy; Narodowy Instytut Fryderyka Chopina {year}
					</span>
				</div>
			</div>
			<div className={styles.imagesWrapper}>
				<img src={chopinFooter2_PL} alt="" />
				<img src={chopinFooter3_PL} alt="" />
				<img src={chopinFooter1_PL} alt="" />
				<img src={chopinFooter4_PL} alt="" />
			</div>
		</div>
	);
}

export default Footer;

