import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from "react-router-dom";

import ModalInput from "../ModalInput/modalInput";
import Criteria from "../Criteria/criteria";
import { AllowedTypes } from "../CriterionFields/criterionFields";

import translation from "../Helpers/translation";
import styles from './advancedInput.module.scss'

import queryString from 'query-string';
import usePrevious from "../Helpers/usePrevious";

function AdvancedInputs(props) {

    let { lang } = useParams();
    const history = useHistory();
    const [allowedTypes,] = useState(AllowedTypes())
    const [open, setOpen] = useState(JSON.parse(props.criteria).length && allowedTypes.includes(`${props.type}`) ? true : false)
    const [criteria, setCriteria] = useState([...JSON.parse(props.criteria), {"field" : 1, "contains" : 1 , "field_text": ''}])
    const [keyword, setKeyword] = useState(props.keyword)
    const [values, setValues] = useState({
        "collection": props.type ? props.type : "",
        "author" : "",
        "date" : "",
        "type" : "",
    })

    const prevValues = usePrevious(values)

    const types = [
        {"name" : "listy", "value" : 1},
        {"name" : "rekopisy", "value" : 2},
        {"name" : "afisze-i-plakaty", "value" : 3},
        {"name" : "fotografie-muzealne", "value" : 4},
        {"name" : "grafika-muzealna", "value" : 5},
        {"name" : "obrazy-muzealne", "value" : 6},
        {"name" : "pocztowki-muzealne", "value" : 7},
        {"name" : "archiwa-muzealne", "value" : 8},
        {"name" : "rzemioslo-muzealne", "value" : 9},
        {"name" : "zbiory-fotograficzne", "value" : 10},
        {"name" : "egzemplarz-ksiazki", "value" : 11},
        {"name" : "rzezby-muzealne", "value" : 12},
        {"name" : "muzeum-inne", "value" : 13},
        {"name" : "druki-muzyczne", "value" : 14},
        {"name" : "audio", "value" : 16},
        {"name" : "video", "value" : 17},
        {"name" : "czasopisma", "value" : 18},
    ]

    const slideDownUp = (onlyHide = true) => {
        /** Slide down. */
        const container = document.getElementById('optionsWrapper');

        if (!container.classList.contains(styles.optionsWrapperOpen) && onlyHide) {
            container.style.display = "flex";
            container.classList.add(styles.optionsWrapperOpen)
            container.style.height = "auto"

            let height = container.clientHeight + "px"

            container.style.height = "0px"

            setTimeout(() => {
                container.style.height = height
            }, 1)
            setTimeout(() => {
                container.style.height = "auto"
                container.style.overflow = "unset"
            }, 401)
            /** Slide up. */
        } else {
            container.style.height = container.clientHeight + "px"
            container.style.overflow = "hidden"

            setTimeout(() => {
                container.style.height = "0px"
                container.classList.remove(styles.optionsWrapperOpen)
            }, 1)

            setTimeout(() => {
                container.style.display = "none";
            }, 401)
        }
    }

    const changeValues = (title,value) => {
        setValues(prevState => {
            let valuesTemp = Object.assign({}, prevState.values);
            valuesTemp[title] = value;
            return {...values, ...valuesTemp};
        });

        if(prevValues.collection !== value) {
            setCriteria([{"field" : 1, "contains" : 1 , "field_text": ''}])
        }
    }

    const toQueryUrl = () => {
        let query = ''
        if(keyword) {
            query = `keyword=${keyword}`
        } else {
            query = `keyword=`
        }

        if(values["collection"]) {
            query = query + `&type=${values["collection"]}`
        } else {
            query = query + `&type=`
        }

        let search_fieldsTmp = criteria
        let search_fields = []

        search_fieldsTmp.forEach((item,index) => {
            if(item.field_text !== '') {
                search_fields.push(item)
            }
        })
        search_fields = JSON.stringify(search_fields)

        if(search_fields.length === 2) {
            query = query + `&search_fields=[]`
        } else {
            query = query + `&${queryString.stringify({search_fields}, {arrayFormat: 'bracket'})}`
        }

        return query
    }

    const search = () => {
        history.push(`/${lang}/wyszukanie-zaawansowane/values?${toQueryUrl()}`)
        window.scrollTo(0,0)
        window.location.reload(false);
    }

    const clear = () => {
        history.push(`/${lang}/wyszukanie-zaawansowane/values?keyword=&type=&search_fields=[]`)
        window.scrollTo(0,0)
        window.location.reload(false);
    }

    const searchOnKeyEnter = (e) => {
        if (e.keyCode === 13 && keyword) {
            window.scrollTo(0,0)
            history.push(`/${lang}/wyszukanie-zaawansowane/values?${toQueryUrl()}`)
            window.location.reload(false);
        }
    }

    const updateKeyWord = (e) => {
        setKeyword(e.target.value)
    }

    const addNewCriteria = () => {
        setCriteria((prev => {
            let criteriaNew = {"field" : 1, "contains" : 1 , "field_text": ''}
            return [...prev, criteriaNew];
        }))
    }

    const updateCriteria = (value, elementId, type) => {
        setCriteria((prev => {
            let valuesTemp = prev;

            if(type === "field") {
                valuesTemp[elementId] = {
                    "field" : parseInt(value),
                    "contains" : prev[elementId].contains ,
                    "field_text": prev[elementId].field_text
                }
            } else if (type === "contains") {
                valuesTemp[elementId] = {
                    "field" : prev[elementId].field,
                    "contains" : parseInt(value) ,
                    "field_text": prev[elementId].field_text
                }
            } else if (type === "field_text") {
                valuesTemp[elementId] = {
                    "field" : prev[elementId].field,
                    "contains" : prev[elementId].contains ,
                    "field_text": value
                }
            }

            return [...valuesTemp];
        }))
    }

    useEffect(() => {
        if(!allowedTypes.includes(values.collection)) {
            setOpen(false)
            setCriteria([{"field" : 1, "contains" : 1 , "field_text": ''}])
            slideDownUp(false)
        }
    },[allowedTypes, values.collection])

    useEffect(() => {
        if(JSON.parse(props.criteria).length) {
            slideDownUp();
        }
    },[props.criteria])

    /*const sendCriteria = () => {
    props.updateCriteria(criteria)
}

const sendDeletedCriteria = (deletedCriteria) => {
    props.updateDeletedCriteria(deletedCriteria)
}

const updateCriteria = (id, values) => {
    setCriteriaCounter([...criteriaCounter, ...[criteriaCounter[criteriaCounter.length - 1] + 1]])
    let criteriaTmp = criteria
    criteriaTmp.push(values)
    sendCriteria()
    setCriteria(criteriaTmp)
}

const deleteCriteriaWrapper = (id) => {
    const container = document.querySelectorAll(`[data-counter~="${id}"]`)[0];
    container.style.display = "none";

    const tempTable = []
    const criteriaTmp = criteria
    for( var i = 0; i < criteriaTmp.length; i++){
        if ( criteriaTmp[i].deleted === true) {
            tempTable.push(criteriaTmp[i])
        }
    }
    sendDeletedCriteria(tempTable)
}



useEffect(() => {
    for (let valuesKey in values) {
        props.updateParams(valuesKey, values[valuesKey])
    }
},[values, props])

useEffect(() => {
    if(allowedTypes.includes(values.collection)) {
        setHiddenFields(false)
    } else {
        slideDownUp(false)
        setOpen(false)
        setCriteria([])
        setHiddenFields(true)
    }
},[allowedTypes, values.collection])

useEffect(() => {
    if(props.criteria && allowedTypes.includes(values.collection)) {
        let tmpCriteria = props.criteria;
        for (let i in props.criteria) {
            tmpCriteria[i].criteriaID = parseInt(i)
        }
        setCriteria(tmpCriteria)
    }
},[allowedTypes, props.criteria, values.collection])

useEffect(() => {
    if(allowedTypes.includes(values.collection)) {
        if(prevCollection !== values.collection && prevCollection !== undefined) {
            setCriteria([])
            setCriteriaCounter([0])
            props.updateCriteria([])
        }
    }
},[allowedTypes, criteria, criteriaCounter, prevCollection, props, values])

useEffect(() => {
    if(criteria.length) {
        slideDownUp();
    }
},[criteria])*/

    /*nowe*/

    /*const [type, setType] = useState(paramType);
    const history = useHistory();

    const updateKeyWord = (e) => {
        setKeyword(e.target.value)
    }

    const updateParams = (key,value) => {
        if(key === 'collection') {
            setType(value)
        }
    }

    const updateCriteria = (value) => {
        setCriteria(value)
    }



*/

    return (
        <>
            <div className={styles.inputWrapper}>
                <input type={"text"} className={`${styles.searchInput} ${styles.searchInputOpen}`} placeholder={translation.searchEverywhere} onChange={updateKeyWord} onKeyUp={searchOnKeyEnter} value={keyword ? keyword : ""}/>
                <div className={`${styles.searchButton} ${styles.searchButtonOpen}`} onClick={search}/>
            </div>
            <div className={styles.inputsWrapper}>
                <ModalInput data={types} title={'collection'} changeValues={changeValues} value={values['collection']}/>
            </div>
            <div className={styles.criterionWrapper}>
                <div
                    className={`${styles.criterion} ${open ? styles.criterionOpen : ''} ${!allowedTypes.includes(values.collection) ? styles.criterionHidden : ''}`}
                    onClick={(event) => {
                        if(allowedTypes.includes(values.collection) || values.collection === "1" || values.collection === "2" || values.collection === "3" || values.collection === "16" || values.collection === "17") {
                            setOpen(!open);
                            slideDownUp();
                        }
                    }}
                >
                    <div className={styles.titlesWrapper}>
                        <span className={styles.plus}>{"+"}</span>
                        <span className={styles.title}>{translation.advancedSearch.criterion}</span>
                    </div>
                </div>
            </div>
            <div className={styles.optionsWrapper} id={'optionsWrapper'}>
                <div className={styles.options}>
                    { criteria ?
                        criteria.map((item,index) => {
                            return (
                                <div key={index}>
                                    <Criteria index={index} category={values.collection} criteria={criteria[index]} open={open} updateCriteria={updateCriteria}/>
                                </div>
                            )
                        })
                        : null
                    }
                    <div className={styles.addCriteria} onClick={addNewCriteria}>
                        {translation.advancedSearch.addCriterion}
                    </div>
                </div>
            </div>
            <div className={styles.buttonsWrapper}>
                <div className={styles.clearButton} onClick={clear}>{translation.clearButton}</div>
                <div className={styles.searchButton} onClick={search}>{translation.searchButton}</div>
            </div>
        </>
    );
}

export default AdvancedInputs;