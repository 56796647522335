import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import translation from "../Helpers/translation";
import itemTypes from "../Helpers/itemTypes";
import scrollToTop from "../Helpers/scrollToTop";

import view_type_1 from "../../assets/image/list/view_type_1.png"
import view_type_2 from "../../assets/image/list/view_type_2.png"
import performances_cover from "../../assets/image/list/performances_cover.jpg"

import styles from "./list.module.scss"

function List(props) {

    let slug = require('slug')
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const typesList = [
        { "name": "letters", "value": 1 },
        { "name": "manuscripts", "value": 2 },
        { "name": "posters", "value": 3 },
        { "name": "museum_photographs", "value": 4 },
        { "name": "museum_graphics", "value": 5 },
        { "name": "museum_paintings", "value": 6 },
        { "name": "museum_postcards", "value": 7 },
        { "name": "museum_archives", "value": 8 },
        { "name": "museum_crafts", "value": 9 },
        { "name": "iconography", "value": 10 },
        { "name": "showpiece", "value": 11 },
        { "name": "museum_sculptures", "value": 12 },
        { "name": "museum_others", "value": 13 },
        { "name": "musicalprint_source", "value": 14 },
        { "name": "audio", "value": 16 },
        { "name": "video", "value": 17 },
        { "name": "journals", "value": 18 },
    ];

    const types = searchParams.getAll('types[]')

    const selectedTypeNames = types.map(typeId => {
        const matchedType = typesList.find(type => type.value === parseInt(typeId))

        if (matchedType) {
            const translatedName = translation.type[matchedType.name];
            return translatedName;
        } else {
            return '';
        }
    })

    const displayName = selectedTypeNames.length === 1
        ? selectedTypeNames[0]
        : translation.collections


    const [listTitlesType, setListTitlesType] = useState(
        sessionStorage.getItem('ListTitlesType')
            ? sessionStorage.getItem('ListTitlesType') === 'true'
                ? true
                : false
            : true
    );

    const image = (item) => {

        switch (item.type) {
            case 'letters':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/letters/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'manuscripts':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/manuscripts/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'posters':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/poster/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_others':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/others/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_sculptures':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/sculptures/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'bibliography':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_foto/bibliography/doc_galleries/medium/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'iconography':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/iconography/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_crafts':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/crafts/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_archives':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/archives/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_postcards':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/postcards/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_paintings':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/paintings/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_graphics':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/graphics/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'museum_photographs':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/museum/photographs/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'musicalprint_source':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/first_edition/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'showpiece':
                return (
                    <div className={styles.mainPhotoNan}>
                        <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/showpiece/${item.id_object}/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                    </div>
                )
            case 'performances':
                if (item.mulitmedia_type === "audio") {
                    return (
                        <div className={styles.mainPhotoNan}>
                            <img loading="lazy" className={styles.photo} src={performances_cover} alt={item.alt_image ? item.alt_image : ""} />
                        </div>
                    )
                } else {
                    return (
                        <div className={styles.mainPhotoNan}>
                            {item.image === "performances_cover.jpg" ? (
                                <div className={`${styles.photo} ${styles.photoVideo}`} />
                            ) : (
                                <>
                                    <div className={`${styles.photo} ${styles.photoVideo}`} style={{ background: `url(https://cms.pmp.edu.pl/_plik/discography2/publishing/${item.image}) no-repeat center`, backgroundSize: "auto 100%" }} />
                                    <div className={`${styles.photo} ${styles.photoVideoImage}`} />
                                </>
                            )}
                        </div>
                    )
                }
            default:
                return null
        }
    }

    const toUrl = (string, title = false) => {
        if (string) {
            string = string.replace(/\u002F/g, "")
            string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            string = string.replace(/\u0142/g, "l")
            string = string.replace(/\u0141/g, "L")
            if (title) {
                return "-" + string.replace(/\s/g, '-')
            } else {
                return string.replace(/\s/g, '-')
            }
        } else {
            return ""
        }
    }

    const trackName = (item) => {
        if (item.performers.length) {
            let names = ""
            for (let i = 0; i < item.performers.length; i++) {
                if (item.performers[i].first_name) {
                    names = names + item.performers[i].first_name + " "
                }
                if (item.performers[i].last_name) {
                    names = names + item.performers[i].last_name
                }
                if (i < item.performers.length - 1) {
                    names = names + ", "
                }
            }
            return names
        } else if (item.performers_text) {
            let string = item.performers_text
            string = string.replace(/\u003B/g, ", ")
            return string
        } else {
            return ""
        }
    }

    const autors = (item) => {
        if (item.authors.length) {
            let names = ""
            for (let i = 0; i < item.authors.length; i++) {
                if (item.authors[i].first_name) {
                    names = names + item.authors[i].first_name + " "
                }
                if (item.authors[i].last_name) {
                    names = names + item.authors[i].last_name
                }
                if (i < item.authors.length - 1) {
                    names = names + ", "
                }
            }
            return names
        } else {
            return false
        }
    }

    const cutTitle = (item) => {
        let string = item.title

        if (item.performers) {
            string = `${trackName(item) + " - "}${item.title} `
        }
        if (item.authors) {
            if (item.authors.length > 0) {
                string = `${item.title} / ${autors(item) ? autors(item) : ""} `
            }
        }

        if (string) {
            if (window.innerWidth > 1601) {
                if (string.length > 100) {
                    return string.substring(0, 100) + '...';
                }
            } else if (1600 > window.innerWidth && window.innerWidth > 1301) {
                if (string.length > 80) {
                    return string.substring(0, 80) + '...';
                }
            } else if (1300 > window.innerWidth && window.innerWidth > 1201) {
                if (string.length > 60) {
                    return string.substring(0, 60) + '...';
                }
            } else if (1200 > window.innerWidth && window.innerWidth > 901) {
                if (string.length > 80) {
                    return string.substring(0, 80) + '...';
                }
            } else if (900 > window.innerWidth && window.innerWidth > 801) {
                if (string.length > 70) {
                    return string.substring(0, 70) + '...';
                }
            } else if (800 > window.innerWidth) {
                return string
            }
        }
        return string
    }

    const listTypeHandler = () => {
        setListTitlesType(!listTitlesType);
        if (listTitlesType) {
            sessionStorage.setItem('ListTitlesType', 'false');
        } else {
            sessionStorage.setItem('ListTitlesType', 'true');
        }
    }

    const saveScrollPosition = () => {
        const position = window.scrollY
        sessionStorage.setItem('position', position.toString());
    }

    const saveOffset = () => {
        sessionStorage.setItem('offset', props.historyOffset);
    }

    const linkCreator = (item) => {
        if (item.mulitmedia_type) {
            if (item.mulitmedia_type === "audio") {
                return `/${props.lang}/galeria-multimedia/${toUrl(itemTypes.type.audio)}/${item.id_object}-${slug(item.title ? item.title : "")}`;
            } else {
                return `/${props.lang}/galeria-multimedia/${toUrl(itemTypes.type.video)}/${item.id_object}-${slug(item.title ? item.title : "")}`;
            }
        } else {
            return `/${props.lang}/galeria-zasobu/${toUrl(itemTypes.type[item.type])}/${item.id_object}-${slug(item.title ? item.title : "")}/0`;
        }
    }

    useEffect(() => {
        const position = sessionStorage.getItem('position')
        if (!props.main) {
            window.scroll(0, parseInt(position))
        }
        sessionStorage.removeItem('position')
    }, [props.main])

    return (
        <div className={styles.listWrapper}>
            <div className={styles.scrollTopButton} onClick={() => scrollToTop(0, 1000)} />
            <div className={styles.top}>
                {!props.searchParam ? (
                    props.dataTop ? (
                        <span className={styles.title}>{props.lang === 'pl' ? props.dataTop.title_pl : props.dataTop.title_en}<div className={styles.line} /></span>
                    ) : (
                        <span className={styles.title}>
                            {displayName}
                            <div className={styles.line} />
                        </span>
                    )
                ) : (
                    <span className={styles.title}>{props.advancedSearch ? translation.advancedSearchTitle : translation.searchTitle}<div className={styles.line} /></span>
                )}
                <span className={styles.description}>{translation.collectionsDescription}</span>
                <div className={styles.changeViewType}>
                    <img className={`${styles.view_type_1} ${listTitlesType ? "" : styles.shadow}`} src={view_type_1} alt={""} onClick={listTypeHandler} />
                    <img className={`${styles.view_type_2} ${listTitlesType ? styles.shadow : ""}`} src={view_type_2} alt={""} onClick={listTypeHandler} />
                </div>
            </div>
            {props.data.length ? (
                <div className={listTitlesType ? styles.listTiles : styles.listNormal}>
                    {!props.isSearch ? (
                        props.data.map((item, index) => {
                            return (
                                props.main ? (
                                    <Link to={`/${props.lang}/kolekcja/${item.id}-${slug(item.title)}`} className={styles.item} key={index}>
                                        <div className={styles.mainPhotoNan}>
                                            {item.image ? (
                                                <img loading="lazy" className={styles.photo} src={`https://cms.pmp.edu.pl/_plik/groups/groups/${item.image}`} alt={item.alt_image ? item.alt_image : ""} />
                                            ) : (
                                                <div className={styles.photo} />
                                            )}
                                        </div>
                                        <div className={styles.description}>
                                            {item.title ?
                                                <span className={styles.title} dangerouslySetInnerHTML={{ __html: `${item.title}` }} />
                                                : null
                                            }
                                        </div>
                                    </Link>
                                ) : (
                                    item.image ? (
                                        <Link to={linkCreator(item)} className={styles.item} key={index} onClick={() => { saveScrollPosition(); saveOffset() }}>
                                            {image(item)}
                                            <div className={styles.description}>
                                                {item.title ?
                                                    <span className={styles.title} dangerouslySetInnerHTML={{ __html: `${cutTitle(item)}` }} />
                                                    : null
                                                }
                                                {!props.main && !listTitlesType ? (
                                                    item.type === "performances" ? (
                                                        <>
                                                            <span className={styles.type}>({translation.type[item.mulitmedia_type]})</span>
                                                        </>
                                                    ) : (
                                                        <span className={styles.type}>({translation.type[item.type]})</span>
                                                    )
                                                ) : null}
                                            </div>
                                        </Link>
                                    ) : null
                                )
                            )
                        })
                    ) : (
                        props.data.map((item, index) => {
                            return (
                                item.image ? (
                                    <Link to={linkCreator(item)} className={styles.item} key={index} onClick={() => { saveScrollPosition(); saveOffset() }}>
                                        {image(item)}
                                        <div className={styles.description}>
                                            {item.title ?
                                                <span className={styles.title} dangerouslySetInnerHTML={{ __html: `${cutTitle(item)}` }} />
                                                : null
                                            }
                                            {
                                                !listTitlesType ? (
                                                    item.type === "performances" ? (
                                                        <>
                                                            <span className={styles.type}>({translation.type[item.mulitmedia_type]})</span>
                                                        </>
                                                    ) : (
                                                        <span className={styles.type}>({translation.type[item.type]})</span>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </Link>
                                ) : null
                            )
                        })
                    )}
                </div>
            ) : (
                props.isSearch ? (
                    <div className={styles.empty}>
                        {props.advSearch
                            ? <span>{translation.emptyAdvSearch}</span>
                            : <span>{`${translation.emptySearch} "${props.searchParam}"`}</span>
                        }
                    </div>
                ) : null
            )}
        </div>
    );
}

export default List;