import React , { useState, useEffect } from 'react';
import axios from "axios";

import translation from "../../Components/Helpers/translation";
import List from "../../Components/List/list";

import styles from './listWrapper.module.scss';
import { FadeOut , Loader} from "../../Components/Loader/loader";
import { useHistory } from "react-router-dom";
import Inputs from "../../Components/Inputs/inputs";
import headerStyles from "../Header/header.module.scss";
import queryString from "query-string";

function MainListWrapper(props) {

	const [lang, ] = useState(window.location.href.split('/')[3]);
	const history = useHistory();
	const [data, setDate] = useState(null);
	const [keyword, setKeyword] = useState()
	const [wrapped, setWrapped] = useState(true)
	const [searchParams, setSearchParams] = useState([])

	const updateSearchParams = (event) => {
		const oldSearchParams = searchParams;
		let push = true;
		for( let i = 0; i < oldSearchParams.length; i++){
			if ( oldSearchParams[i] === event.target.value) {
				push = false
			}
		}
		if (push) {
			oldSearchParams.push(event.target.value)
		} else {
			const oldSearchParams = searchParams;
			for( let i = 0; i < oldSearchParams.length; i++){
				if ( oldSearchParams[i] === event.target.value) {
					oldSearchParams.splice(i, 1);
				}
			}
			setSearchParams(oldSearchParams)
		}
	}

	const updateKeyWord = (e) => {
		setKeyword(e.target.value)
	}
	
	useEffect(() => {
		const cleanUrl = (url) => {
			const urlObj = new URL(url);
			const paramsToRemove = ['_gl', '_ga', '_gcl_aw', '_gcl_au'];
			paramsToRemove.forEach(param => urlObj.searchParams.delete(param));
			return `${urlObj.origin}${urlObj.pathname}`; 
		};
	
		const cleanedUrl = cleanUrl(window.location.href);
	
		window.history.replaceState({}, document.title, cleanedUrl);
	}, []);

	useEffect(() => {
		const tmpLang = lang.split('?')[0];
		axios
			.post(props.url + "index.php/groups/search?lang=" + tmpLang)
			.then(res => {
				setDate(res.data)
				FadeOut("loader");
			})
	},[lang, props.url])


	

	const toQueryUrl = () => {
		let query = ''
		if(keyword) {
			query = `keyword=${keyword}`
		} else {
			query = `keyword=`
		}
		if(searchParams.length) {
			query = query + `&${queryString.stringify({types: searchParams}, {arrayFormat: 'bracket'})}`
		} else {
			query = query +  `&types[]=`
		}
		return query
	}

	const search = () => {
		history.push(`/${lang}/wyszukanie/values?${toQueryUrl()}`)
		window.location.reload(false);
	}

	const clear = () => {
		history.push(`/${lang}/wyszukanie/values?keyword=&types[]=`)
		window.location.reload(false);
	}

	const searchOnKeyEnter = (e) => {
		if (e.keyCode === 13 && keyword) {
			history.push(`/${lang}/wyszukanie/values?${toQueryUrl()}`)
			window.location.reload(false);
		}
	}

	useEffect(() => {
		let header = document.getElementById("header")
		header.classList.add(headerStyles.header)
		if (!wrapped) {
			if(header.classList.contains(headerStyles.header)) {
				header.classList.remove(headerStyles.header)
			} else {
				header.classList.add(headerStyles.header)
			}
		}
	},[wrapped])

	return (
		<>
			<Loader/>
			{data ? (
				<div className={styles.wrapper}>
					<div className={`${styles.searchWrapper} ${wrapped ? '' : styles.searchWrapperOpen}`}>
						<div className={styles.inputWrapper}>
							<input type={"text"} className={`${styles.searchInput} ${wrapped ? '' : styles.searchInputOpen}`} placeholder={translation.search} onChange={updateKeyWord} onKeyUp={searchOnKeyEnter}/>
							<div className={`${styles.searchButton} ${wrapped ? '' : styles.searchButtonOpen}`} onClick={search}/>
						</div>
						<Inputs wrapped={wrapped} update={updateSearchParams} checked={searchParams} clear={clear} search={search}/>
						<span className={styles.advSearch}>{translation.advSearch}</span>
						<span className={styles.advSearchIco} onClick={() =>{setWrapped(!wrapped); window.scroll(0,0);}}/>
					</div>
					<List listTitlesType={false} data={data} main={props.main} lang={lang}/>
				</div>
			) : null}
		</>
	);
}

export default MainListWrapper;