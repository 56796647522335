import translation from "../Helpers/translation";

function CriterionFields (category) {

    const fieldsLetters = [
        {"name" : translation.advancedSearch.fields["1"].sender , "value" : 1},
        {"name" : translation.advancedSearch.fields["1"].recipients , "value" : 2},
        {"name" : translation.advancedSearch.fields["1"].placeFrom , "value" : 3},
        {"name" : translation.advancedSearch.fields["1"].placeTo , "value" : 4},
        {"name" : translation.advancedSearch.fields["1"].dateFrom , "value" : 6},
        {"name" : translation.advancedSearch.fields["1"].dataTo , "value" : 5},
        {"name" : translation.advancedSearch.fields["1"].signature , "value" : 7}
    ]

    const fieldsManuscripts = [
        {"name" : translation.advancedSearch.fields["2"].title , "value" : 1},
        {"name" : translation.advancedSearch.fields["2"].author , "value" : 2},
        {"name" : translation.advancedSearch.fields["2"].data , "value" : 3},
        {"name" : translation.advancedSearch.fields["2"].place , "value" : 4},
        {"name" : translation.advancedSearch.fields["2"].dedication , "value" : 5},
        {"name" : translation.advancedSearch.fields["2"].signature , "value" : 6},
        {"name" : translation.advancedSearch.fields["2"].category , "value" : 7}
    ]

    const fieldsPosters = [
        {"name" : translation.advancedSearch.fields["3"].title , "value" : 1},
        {"name" : translation.advancedSearch.fields["3"].author , "value" : 2},
        {"name" : translation.advancedSearch.fields["3"].place , "value" : 3},
        {"name" : translation.advancedSearch.fields["3"].data , "value" : 4},
        {"name" : translation.advancedSearch.fields["3"].lang , "value" : 5},
        {"name" : translation.advancedSearch.fields["3"].signature , "value" : 6},
        {"name" : translation.advancedSearch.fields["3"].subject , "value" : 7}
    ]

    const muzeumPosters = [
        {"name" : translation.advancedSearch.fields["4"].title , "value" : 1},
        {"name" : translation.advancedSearch.fields["4"].author , "value" : 2},
        {"name" : translation.advancedSearch.fields["4"].data , "value" : 3},
        {"name" : translation.advancedSearch.fields["4"].place , "value" : 4},
        {"name" : translation.advancedSearch.fields["4"].signature , "value" : 5}
    ]

    const musicalPrintSource = [
        {"name" : translation.advancedSearch.fields["5"].title , "value" : 1},
        {"name" : translation.advancedSearch.fields["5"].author , "value" : 2},
        {"name" : translation.advancedSearch.fields["5"].data , "value" : 3},
        {"name" : translation.advancedSearch.fields["5"].place , "value" : 4},
        {"name" : translation.advancedSearch.fields["5"].publisher , "value" : 5},
        {"name" : translation.advancedSearch.fields["5"].subject_tag , "value" : 6},
        {"name" : translation.advancedSearch.fields["5"].signature , "value" : 7}
    ]

    const audioWideo = [
        {"name" : translation.advancedSearch.fields["6"].compositions , "value" : 1},
        {"name" : translation.advancedSearch.fields["6"].artists , "value" : 2},
        {"name" : translation.advancedSearch.fields["6"].edition , "value" : 3},
        {"name" : translation.advancedSearch.fields["6"].instruments , "value" : 4},
        {"name" : translation.advancedSearch.fields["6"].date , "value" : 5},
    ]

    const magazines = [
        {"name" : translation.advancedSearch.fields["7"].title , "value" : 1},
        {"name" : translation.advancedSearch.fields["7"].number , "value" : 2},
        {"name" : translation.advancedSearch.fields["7"].volume , "value" : 3},
        {"name" : translation.advancedSearch.fields["7"].date , "value" : 4},
    ]

    switch (category) {
        case '1':
            return fieldsLetters;
        case '2':
            return fieldsManuscripts;
        case '3':
            return fieldsPosters;
        case '4' :
        case '5' :
        case '6' :
        case '7' :
        case '8' :
        case '9' :
        case '12' :
        case '13' :
            return muzeumPosters;
        case '14' :
            return musicalPrintSource;
        case '16' :
        case '17' :
            return audioWideo;
        case '18' :
            return magazines;
        default:
            return null;
    }
}

function AllowedTypes() {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '12', '13', '14', '16', '17', '18']
}

export { CriterionFields, AllowedTypes }