import React , { useEffect, useState }  from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";
import performances_cover from "../../../assets/image/list/performances_cover.jpg"

import styles from "../itemTypes.module.scss"

function Multimedia(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const loopGallery = (imagesTable) => {
        let table = []

        for (let imageData in imagesTable) {
            table.push(imagesTable[imageData].file_name)
        }

        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery))
    }, [props.data.gallery])

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        <Link to={`/${lang}/galeria-multimedia/${type}/${id}/0`}>
                            <img loading="lazy" src={performances_cover} className={styles.imageMultimedia} alt={props.data.alt_photo} style={{border: "1px solid #ffffff", boxShadow: "0 0 4px #ffffff"}}/>
                        </Link>
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.name ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.name}`}}/>
                        ) : null}
                        {/*{props.data.subtitle ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.subtitle}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.subtitle}`}}/>
                            </div>
                        ) : null}
                        {props.data.main ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.main}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.main}`}}/>
                            </div>
                        ) : null}
                        {props.data.author ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.author}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author}`}}/>
                            </div>
                        ) : null}
                        {props.data.author_institute ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.author_institute}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author_institute}`}}/>
                            </div>
                        ) : null}
                        {props.data.date_human ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.date_human}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.date_human}`}}/>
                            </div>
                        ) : null}
                        {props.data.dimensions ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.dimensions}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.dimensions}`}}/>
                            </div>
                        ) : null}
                        {props.data.material ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.material}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.material}`}}/>
                            </div>
                        ) : null}
                        {props.data.technique ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.technique}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.technique}`}}/>
                            </div>
                        ) : null}
                        {props.data.event_associated ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.event_associated}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.event_associated}`}}/>
                            </div>
                        ) : null}
                        {props.data.place_associated ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.place_associated}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.place_associated}`}}/>
                            </div>
                        ) : null}
                        {props.data.place_of_creation ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.place_of_creation}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.place_of_creation}`}}/>
                            </div>
                        ) : null}
                        {props.data.persons.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.persons.persons}</span>
                                {
                                    props.data.persons.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.iconography.persons.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.biogram ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.iconography.persons.biogram}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.biogram}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.institutions.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.iconography.institutions.institutions}</span>
                                {
                                    props.data.institutions.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.iconography.institutions.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.biogram ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.iconography.institutions.biogram}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.biogram}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }*/}
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Multimedia;
