import React, { useEffect, useState } from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function MusicalPrints(props) {

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const [gallery, setGallery] = useState([])

    const loopGallery = (imagesTable , url = null) => {
        let table = []
        if (!url) {
            for (let imageData in imagesTable) {
                table.push(imagesTable[imageData].file_name)
            }
        } else {
            for (let imageData in imagesTable) {
                table.push(url + imagesTable[imageData].file_name)
            }
        }
        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery))
    }, [props.data.gallery, props.imageLink])

    const persons = () => {
        if(props.data.persons) {
            let names = ""
            for(let i = 0; i < props.data.persons.length; i++) {
                if (props.data.persons[i].id) {
                    if(props.data.persons[i].name) {
                        names =`${names} <a href="https://redirect.nifc.pl/person/${props.data.persons[i].id}/${lang}" target="_blank">${props.data.persons[i].name}</a>`
                    }
                } else {
                    if(props.data.persons[i].name) {
                        names = names + props.data.persons[i].name + " "
                    }
                }

                if(i < props.data.persons.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        { persons() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.author}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${persons()}`}}/>
                            </div>
                            : props.data.author ? (
                                <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.musicalprint_source.author}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author}`}}/>
                                </div>
                            ) : null
                        }
                        {props.data.publishing_house ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.publishing_house}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publishing_house}`}}/>
                            </div>
                        ) : null}
                        {props.data.titlepage ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.titlepage}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.titlepage}`}}/>
                            </div>
                        ) : null}
                        {props.data.release_place ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.release_place}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.release_place}`}}/>
                            </div>
                        ) : null}
                        {props.data.release_number ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.release_number}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.release_number}`}}/>
                            </div>
                        ) : null}
                        {props.data.publication_year ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.publication_year}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publication_year}`}}/>
                            </div>
                        ) : null}
                        {props.data.printing_house ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.printing_house}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.printing_house}`}}/>
                            </div>
                        ) : null}
                        {props.data.place_of_printing ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.place_of_printing}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.place_of_printing}`}}/>
                            </div>
                        ) : null}
                        {props.data.number_of_pages ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.number_of_pages}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.number_of_pages}`}}/>
                            </div>
                        ) : null}
                        {props.data.format ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.format}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.format}`}}/>
                            </div>
                        ) : null}
                        {props.data.cast ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.cast}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.cast}`}}/>
                            </div>
                        ) : null}
                        {props.data.subjects ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.subjects}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.subjects}`}}/>
                            </div>
                        ) : null}
                        {props.data.print_technique ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.print_technique}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.print_technique}`}}/>
                            </div>
                        ) : null}
                        {props.data.bibliography ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.bibliography}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.bibliography}`}}/>
                            </div>
                        ) : null}
                        {props.data.signature ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.signature}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.signature}`}}/>
                            </div>
                        ) : null}
                        {props.data.siglum ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.siglum}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.siglum}`}}/>
                            </div>
                        ) : null}
                        {props.data.txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.manuscripts ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.manuscripts.manuscripts}</span>
                                {
                                    props.data.manuscripts.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.manuscripts.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.category ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.manuscripts.category}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.category}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.place_of_storage ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.manuscripts.place_of_storage}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.place_of_storage}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.area_name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.manuscripts.area_name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.area_name}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.country_name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.manuscripts.country_name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.country_name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.compositions ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.compositions.compositions}</span>
                                {
                                    props.data.compositions.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.compositions.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.opus ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.compositions.opus}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.opus}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.main ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.compositions.main}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.main}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.dedications ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.dedications.dedications}</span>
                                {
                                    props.data.other_editions.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.other_editions.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.other_editions? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.other_editions.other_editions}</span>
                                {
                                    props.data.other_editions.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.other_editions.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.titlepage ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.other_editions.titlepage}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.titlepage}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.publishinghouse ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.other_editions.publishinghouse}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.publishinghouse}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.country ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.other_editions.country}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.country}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.humdrums ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.humdrums.humdrums}</span>
                                {
                                    props.data.humdrums.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.humdrums.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.file_name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.humdrums.file_name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.file_name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.rism ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.musicalprint_source.rism.rism}</span>
                                {
                                    props.data.humdrums.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                { item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.humdrums.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                { item.file_name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.musicalprint_source.humdrums.file_name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.file_name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default MusicalPrints;