import React , { useEffect, useState }  from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Bibliography(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const loopGallery = (imagesTable ,url) => {
        let table = []

        for (let imageData in imagesTable) {
            table.push(url + imagesTable[imageData].file_name)
        }

        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery, "https://cms.pmp.edu.pl/_foto/bibliography/doc_galleries/medium/"))
    }, [props.data.gallery])

    const institutions = () => {
        if(props.data.institutions) {
            let names = ""
            if(props.data.institutions.id) {
                if(props.data.institutions.name) {
                    names = `${names} <a href="https://redirect.nifc.pl/organisation/${props.data.institutions.id}/${lang}" target="_blank">${props.data.institutions.name}</a>`
                }
            } else {
                if(props.data.institutions.name) {
                    names = names + props.data.institutions.name + " "
                }
            }

            return `${names}`
        } else {
            return null
        }
    }

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        {props.data.subtitle ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.subtitle}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.subtitle}`}}/>
                            </div>
                        ) : null}
                        {props.data.date_of_publishing_text ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.date_of_publishing_text}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.date_of_publishing_text}`}}/>
                            </div>
                        ) : null}
                        {props.data.date_of_publishing_date ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.date_of_publishing_date}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.date_of_publishing_date}`}}/>
                            </div>
                        ) : null}
                        {props.data.copyright ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.copyright}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.copyright}`}}/>
                            </div>
                        ) : null}
                        {props.data.language ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.language}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.language}`}}/>
                            </div>
                        ) : null}
                        {props.data.publication_number ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.publication_number}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publication_number}`}}/>
                            </div>
                        ) : null}
                        {props.data.circulation_number ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.circulation_number}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.circulation_number}`}}/>
                            </div>
                        ) : null}
                        {props.data.isbn ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.isbn}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.isbn}`}}/>
                            </div>
                        ) : null}
                        {props.data.keywords ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.keywords}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.keywords}`}}/>
                            </div>
                        ) : null}
                        {props.data.txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.category_id ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.category_id}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.category_id}`}}/>
                            </div>
                        ) : null}
                        {props.data.category ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.category}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.category}`}}/>
                            </div>
                        ) : null}
                        {props.data.physical_description ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.physical_description}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.physical_description}`}}/>
                            </div>
                        ) : null}
                        {props.data.series ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.series}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.series}`}}/>
                            </div>
                        ) : null}
                        {props.data.journal ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journal}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journal}`}}/>
                            </div>
                        ) : null}
                        {props.data.cover ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.cover}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.cover}`}}/>
                            </div>
                        ) : null}
                        {props.data.pages_num ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.pages_num}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.pages_num}`}}/>
                            </div>
                        ) : null}
                        {props.data.format ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.format}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.format}`}}/>
                            </div>
                        ) : null}
                        {props.data.shop_url ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.shop_url}</span>
                                <a href={props.data.shop_url}><span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.shop_url}`}}/></a>
                            </div>
                        ) : null}
                        {props.data.id_seriesnumber ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.id_seriesnumber}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.id_seriesnumber}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.subtitle ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.subtitle}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.subtitle}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.publishing_year ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.publishing_year}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.publishing_year}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.isbn ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.isbn}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.isbn}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.format ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.format}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.format}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.physical_description ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.physical_description}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.physical_description}`}}/>
                            </div>
                        ) : null}
                        {institutions() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.publishing_house}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${institutions()}`}}/>
                            </div>
                            : props.data.journalnumber.publishing_house ? (
                                <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.bibliography.journalnumber.publishing_house}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.publishing_house}`}}/>
                                </div>
                            ) : null
                        }
                        {props.data.journalnumber.area ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.area}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.area}`}}/>
                            </div>
                        ) : null}
                        {props.data.journalnumber.txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.bibliography.journalnumber.txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.journalnumber.txt}`}}/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Bibliography;