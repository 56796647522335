import React , { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
import './video-styles.scss'

import Menu from "../../Components/Menu/menu";

import { secondsToMinutes} from "../../Components/Helpers";
import {FadeOut, Loader} from "../../Components/Loader/loader";
import noPoster from "../../assets/image/gallery/noPoster.jpg"
import translation from "../../Components/Helpers/translation";

import styles from "./gallery.module.scss"
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import useWindowSize from "../../Components/Helpers/useWindowSize";

function GalleryMultimedia(props) {

    let history = useHistory();

    function handleClick() {
        history.goBack()
    }

    let { lang ,type, id } = useParams();
    id = parseInt(id);
    type = type.toString()

    const [data, setDate] = useState(null);
    const [dataPrograms, setDataPrograms] = useState([]);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [durationPart, setDurationPart] = useState(0);
    const [fetched, setFetched] = useState(false);
    const size = useWindowSize();

    const videoPlayerRef = useRef();

    const trackName = () => {

        if(data.performers.length) {
            let names = ""
            for(let i = 0; i < data.performers.length; i++) {
                if(data.performers[i].first_name) {
                    names = names + data.performers[i].first_name + " "
                }
                if(data.performers[i].last_name) {
                    names = names + data.performers[i].last_name
                }
                if(i < data.performers.length - 1) {
                    names = names + ", "
                }
            }
            return `${names} - ${data.name}`
        } else if(data.performers_text) {
            let string = data.performers_text
            string = string.replace(/\u003B/g, ", ")
            return `${string} - ${data.name}`

        } else {
            return data.name
        }
    }

    const artists = () => {
        if(data.performers.length) {
            let names = ""
            for(let i = 0; i < data.performers.length; i++) {
                let name = ""
                if(data.performers[i].first_name) {
                    name = name + data.performers[i].first_name + " "
                }
                if(data.performers[i].last_name) {
                    name = name + data.performers[i].last_name
                }
                names =`${names} <a href="https://redirect.nifc.pl/person/${data.performers[i].id}/${lang}" target="_blank">${name}</a>`
                if(i < data.performers.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    const composition = () => {
        if(data.compositions.length) {
            let names = ""
            for(let i = 0; i < data.compositions.length; i++) {
                let name = ""
                if(data.compositions[i].name) {
                    name = name + data.compositions[i].name + " "
                }
                if(data.compositions[i].opus) {
                    name = name + data.compositions[i].opus
                }
                names =`${names} <a href="https://redirect.nifc.pl/composition/${data.compositions[i].id}/${lang}" target="_blank">${name}</a>`
                if(i < data.compositions.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    const institutions = () => {
        if(data.institutions.length) {
            let names = ""
            for(let i = 0; i < data.institutions.length; i++) {
                if(data.institutions[i].name) {
                    names = `${names} <a href="https://redirect.nifc.pl/organisation/${data.institutions[i].id}/${lang}" target="_blank">${data.institutions[i].name}</a>`
                }
                if(i < data.institutions.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
        let player = videojs(videoPlayerRef.current);
        if(isPlaying) {
            player.pause()
        } else {
            player.play()
        }
    }

    const seekTo = e => {
        let player = videojs(videoPlayerRef.current);
        player.currentTime((duration * e.currentTarget.value/100).toFixed(5));
    }

    useEffect(() => {
        
        if(data) {
            let player;

            if(data.mulitmedia_type === "video") {
                player = videojs(videoPlayerRef.current, { "controls": true, "preload": "auto","fluid": true, "poster": ` ${data.image ? `https://cms.pmp.edu.pl/_plik/discography2/publishing/${data.image}` : noPoster}`}, () => {
                    player.src(`https://storage.nifc.pl/audio_video/video/performances/${data.id_old_perfomance}/hls/playlist.m3u8`)
                })
            } else {
                player = videojs(videoPlayerRef.current, {}, () => {
                    player.src(`https://storage.nifc.pl/audio_video/audio/performances/${data.id_old_perfomance}/hls/playlist.m3u8`)
                })
            }

            if (player.readyState() < 1) {
                player.one("loadedmetadata", onLoadedMetadata);
                player.one("play", onplay)
                player.one("pause", onpause)
                player.one("stop", onstop)
                player.on('timeupdate', timeupdate);
            } else {
                onLoadedMetadata();
            }

            function onLoadedMetadata() {
                setDuration(player.duration());
                FadeOut("loader");
            }

            function onplay() {
                setIsPlaying(true);
            }

            function onpause() {
                setIsPlaying(false);
            }

            function onstop() {
                setIsPlaying(false);
            }

            function timeupdate() {
                setDurationPart((player.currentTime() / player.duration() * 100).toFixed(5));
                setCurrentTime(player.currentTime())
                if (player.currentTime() === player.duration()) {
                    setIsPlaying(false);
                }
            }

            return () => {
                player.dispose();
            }
        }
        
    }, [data])

    useEffect(() => {
        axios
            .post(props.url + "index.php/discography2/get_performance_by_id/" + id + "?lang=" + lang)
            .then(res => {
                setDate(res.data)
                if(!res.data) {
                    window.location.href ="/"
                }
            })
    },[id, lang, props.url, type])

    useEffect(() => {
        if(data && !fetched) {
            for(let i = 0; i < data.programs.length; i++) {
                axios
                    .post(props.url + "index.php/events/get_program_by_id/" + data.programs[i].id + "?lang=" + lang)
                    .then(res => {
                        let oldDataPrograms = dataPrograms;
                        if(res.data) {
                            oldDataPrograms.push(res.data)
                        }
                        setDataPrograms(oldDataPrograms)
                    });
            }
            setFetched(true)
        }
    },[data, dataPrograms, fetched, id, lang, props.url])

    const resizeSocialIcons = () => {
        if(size.width >= 801 ) {
            return 55
        } else {
            return 38
        }
    }

    const description = () => {
        return (
            <>
                {dataPrograms.length ? (
                    dataPrograms.map((value, index) => {
                        return (
                            <div key={index} className={styles.descriptionWrapper}>
                                { value.group_title ?
                                    <div className={styles.description}>
                                        <span style={{fontWeight: 600}}>{translation.galleryMultimedia["edition"]}</span>
                                        { value.group_url
                                            ? <a href={value.group_url} target={"_blank"} rel={"noreferrer"} dangerouslySetInnerHTML={{__html: `&nbsp${value.group_title}`}}/>
                                            : <span dangerouslySetInnerHTML={{__html: `&nbsp${value.group_title}`}}/>
                                        }
                                    </div>
                                    : null
                                }
                                { composition() ?
                                    <div className={styles.description}>
                                        <span style={{fontWeight: 600}}>{translation.galleryMultimedia["compositions"]}</span>
                                        <span dangerouslySetInnerHTML={{__html: `&nbsp${composition()}`}}/>
                                    </div>
                                    : null
                                }
                                { value.subgroup_title ?
                                    <div className={styles.description}>
                                        {/*<span style={{fontWeight: 600}}>{translation.galleryMultimedia["stage"]}</span>*/}
                                        <span dangerouslySetInnerHTML={{__html: `${value.subgroup_title}`}}/>
                                    </div>
                                    : null
                                }
                                { artists() ?
                                    <div className={styles.description}>
                                        <span style={{fontWeight: 600}}>{translation.galleryMultimedia["artists"]}</span>
                                        <span dangerouslySetInnerHTML={{__html: `&nbsp${artists()}`}}/>
                                    </div>
                                    : null
                                }
                                { institutions() ?
                                    <div className={styles.description}>
                                        <span style={{fontWeight: 600}}>{translation.galleryMultimedia["institutions"]}</span>
                                        <span dangerouslySetInnerHTML={{__html: `&nbsp${institutions()}`}}/>
                                    </div>
                                    : null
                                }
                            </div>
                        )
                    })
                ) : null}
                <div className={ `${styles.descriptionWrapper} ${styles.descriptionInstrument}`}>
                    {data.instrument_name_txt ? (
                        <div className={styles.description}>
                            <span style={{fontWeight: 600}}>{translation.galleryMultimedia["instrument_name_txt"]}</span>
                            <span dangerouslySetInnerHTML={{__html: `&nbsp${data.instrument_name_txt}`}}/>
                        </div>
                    ): null}
                    {data.shelfmark ? (
                        <div className={styles.description}>
                            <span style={{fontWeight: 600}}>{translation.galleryMultimedia["signature"]}</span>
                            <span dangerouslySetInnerHTML={{__html: `&nbsp${data.shelfmark}`}}/>
                        </div>
                    ): null}
                    {data.created_at ? (
                        <div className={styles.description}>
                            <span style={{fontWeight: 600}}>{translation.galleryMultimedia["created_at"]}</span>
                            <span dangerouslySetInnerHTML={{__html: `&nbsp${data.created_at}`}}/>
                        </div>
                    ): null}
                </div>
            </>
        )
    }

    return (
        <>
            <Loader offlineDisable={true} />
            {data ? (
                <div className={styles.wrapperAudio}>
                    <div className={styles.rightMenuVertical}>
                        <div className={styles.ueLogo}/>
                        <FacebookShareButton url={window.location.href}>
                            <FacebookIcon size={resizeSocialIcons()} />
                        </FacebookShareButton>

                        <TwitterShareButton url={window.location.href}>
                            <TwitterIcon size={resizeSocialIcons()} />
                        </TwitterShareButton>

                        <LinkedinShareButton url={window.location.href}>
                            <LinkedinIcon size={resizeSocialIcons()} />
                        </LinkedinShareButton>
                    </div>
                    <div className={styles.galleryMultimedia}>
                        <div className={styles.leftMenu}>
                            <span className={styles.back} onClick={handleClick}/>
                            <Menu/>
                        </div>
                        {data.mulitmedia_type === "audio" ? (
                            <div className={styles.multimediaWrapper}>
                                <div className={styles.audioPlayer}>
                                    <div className={styles.title} dangerouslySetInnerHTML={{__html: trackName()}}/>
                                    <video ref={videoPlayerRef} className="video-js" controls style={{display: "none"}}/>
                                    <button className={`${styles.play} ${isPlaying ? styles.playing : ""}`} onClick={togglePlay}/>
                                    <div className={styles.controls}>
                                        <span className={styles.currentTime}>{secondsToMinutes(currentTime)}</span>
                                        <div className={styles.progress}>
                                            <div className={styles.progressWrapper}>
                                                <input type="range" min="0" max="100" onClick={seekTo} />
                                                <div className={styles.progressSection}>
                                                    <div className={styles.progressBar} style={{width: `${durationPart}%`}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <span className={styles.fullTime}>{secondsToMinutes(duration)}</span>
                                    </div>
                                    {description()}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.multimediaWrapper}>
                                <div className={styles.videoPlayer}>
                                    <video ref={videoPlayerRef} className={`video-js vjs-sublime-skin ${styles.player}`} />
                                    <div className={styles.title} dangerouslySetInnerHTML={{__html: trackName()}}/>
                                    {description()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>)
}

export default GalleryMultimedia;