import React, { useEffect, useState } from 'react';

import styles from './criteria.module.scss'
import ModalInputCriterion from "../ModalInputCriterion/modalInputCriterion";
import translation from "../Helpers/translation";
import { CriterionFields, AllowedTypes } from "../CriterionFields/criterionFields";

function Criteria(props) {

    const [contains,] = useState([
        {"name" : translation.advancedSearch.criterions.contains , "value" : 1},
        {"name" : translation.advancedSearch.criterions.doNotContain , "value" : 0}
    ])
    const [fields, setFields] = useState(CriterionFields("1"))
    const [allowedTypes, ] = useState(AllowedTypes())

    const changeCriteria = (value) => {
        props.updateCriteria(value, props.index,"field_text")
    }

    const changeDate = (title, e) => {

        e.preventDefault();
        let tempValue = props.criteria.field_text
        let len = tempValue.length;
        if(e.keyCode !== 8) {
            if(len === 4 || len === 7) {
                tempValue += '-';
            }
        }
        if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            if((props.criteria.field_text.length < 10 && tempValue.length !== 4) && (props.criteria.field_text.length < 10 && tempValue.length !== 7)) {
                tempValue += e.key
            }
        } else if( e.keyCode === 8) {
            tempValue = tempValue.slice(0, tempValue.length - 1)
        }

        props.updateCriteria(tempValue, props.index,"field_text")
    }

    const placeholder = (category) => {

        if(category === "1") {
            if( props.criteria.field === 5 || props.criteria.field === 6 ) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else if(category === "2") {
            if( props.criteria.field === 3) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else if(category === "3") {
            if (props.criteria.field === 4) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else if(category === "16" || category === "17") {
            if (props.criteria.field === 5) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else if(category === "18") {
            if (props.criteria.field === 4) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else if(allowedTypes.includes(category)) {
            if (props.criteria.field === 3) {
                return "YYYY-MM-DD"
            } else {
                return translation.advancedSearch.textEntry
            }
        } else {
            return translation.advancedSearch.textEntry
        }
    }

    useEffect(() => {
        if(allowedTypes.includes(props.category)) {
            setFields(CriterionFields(props.category))
        }
    },[allowedTypes, props.category])

    return (
        <div className={styles.wrapper} data-counter={props.index}>
            <ModalInputCriterion index={props.index} title={`field-${props.index}`} data={fields} name={"field"} updateCriteria={props.updateCriteria} value={props.criteria.field} open={props.open} category={props.category}/>
            <ModalInputCriterion index={props.index} title={`contains-${props.index}`} data={contains} name={"contains"} updateCriteria={props.updateCriteria} value={props.criteria.contains} open={props.open}/>
            <div className={styles.inputWrapper}>
                <input type={"text"}
                   className={`${styles.searchInput} ${styles.searchInputOpen}`}
                   placeholder={placeholder(props.category)}
                   onChange={(e) => {
                       if(props.category === "1") {
                           if (props.criteria.field !== 5 || props.criteria.field !== 6) {
                               changeCriteria(e.target.value)
                           }
                       } else if(props.category === "2") {
                           if (props.criteria.field !== 3) {
                               changeCriteria(e.target.value)
                           }
                       } else if(props.category === "3") {
                           if (props.criteria.field !== 4) {
                               changeCriteria(e.target.value)
                           }
                       } else if(props.category === "16" || props.category === "17") {
                           if (props.criteria.field !== 5) {
                               changeCriteria(e.target.value)
                           }
                       } else if(props.category === "18") {
                           if (props.criteria.field !== 4) {
                               changeCriteria(e.target.value)
                           }
                       } else if(allowedTypes.includes(props.category)) {
                           if (props.criteria.field !== 3) {
                               changeCriteria(e.target.value)
                           }
                       }
                   }}
                   onKeyDown={(e) => {
                       if(props.category === "1") {
                           if (props.criteria.field === 5 || props.criteria.field === 6) {
                               changeDate("field_text", e)
                           }
                       } else if(props.category === "2") {
                           if (props.criteria.field === 3) {
                               changeDate("field_text", e)
                           }
                       } else if(props.category === "3") {
                           if (props.criteria.field === 4) {
                               changeDate("field_text", e)
                           }
                       } else if(props.category === "16" || props.category === "17") {
                           if (props.criteria.field !== 5) {
                               changeDate("field_text", e)
                           }
                       } else if(props.category === "18") {
                           if (props.criteria.field !== 4) {
                               changeDate("field_text", e)
                           }
                       } else if(allowedTypes.includes(props.category)) {
                           if (props.criteria.field === 3) {
                               changeDate("field_text", e)
                           }
                       }
                   }}
                   value={props.criteria.field_text ? props.criteria.field_text : ''}
                />
            </div>
        </div>
    )
}

export default Criteria;