import React, { useEffect, useState, useCallback } from 'react';
import { AllowedTypes } from "../CriterionFields/criterionFields";

import styles from './modalInputCriterion.module.scss'

function ModalInputCriterion(props) {

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(props.data[0].name);
    const [allowedTypes, ] = useState(AllowedTypes())

    useEffect(() => {
        let result = props.data.filter(obj => {
            return obj.value === props.value
        })
        setTitle(result[0].name)
    },[props.data, props.value])

    const slideDownUp = useCallback((onlyHide = true) => {
        const container = document.querySelectorAll(`[data-name~=${props.title}]`)[0].children[2];

        if ((!container.classList.contains(styles.modalWrapperOpen) && props.name === "field" && onlyHide) ||
            (!container.classList.contains(styles.modalWrapperOpenNoScroll) && props.name === "contains" && onlyHide)) {
            if(props.name === "contains") {
                container.classList.add(styles.modalWrapperOpenNoScroll)
            } else {
                container.classList.add(styles.modalWrapperOpen)
            }

            container.style.height = "auto"
            let height = container.clientHeight + "px"
            container.style.height = "0px"

            setTimeout(() => {
                container.style.height = height
            }, 1)
            /** Slide up. */
        } else {
            container.style.height = container.clientHeight + "px"
            container.style.height = "0px"

            setTimeout(() => {
                if(props.name === "contains") {
                    container.classList.remove(styles.modalWrapperOpenNoScroll)
                } else {
                    container.classList.remove(styles.modalWrapperOpen)
                }

            }, 401)
            setOpen(false)
        }
    },[props.name, props.title])

    useEffect(() => {
        if(!props.open) {
            slideDownUp(false)
        }
    },[props.open, slideDownUp])

    const clearFieldForData = (value) => {

        if(props.category === "1") {
            if([1,2,3,4,7].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        } else if (props.category === "2") {
            if([1,2,4,5,6,7].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        } else if (props.category === "3") {
            if([1,2,3,5,6,7].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        } else if (props.category === "16" || props.category === "17") {
            if([1,2,3,4].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        } else if (props.category === "18") {
            if([1,2].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        } else if (allowedTypes.includes(props.category)) {
            if([1,2,4,5].includes(value)) {
                props.updateCriteria("", props.index,"field_text")
            }
        }
    }

    return (
        <div
            className={`${styles.inputWrapper} ${open ? styles.inputWrapperOpen : ''} ${title ? styles.inputWrapperSeted : ''} ${props.hidden ? styles.inputWrapperHidden : ''}`}
            onClick={(event) => {
                setOpen(!open);
                slideDownUp(event)
            }}
            data-name={props.title}
        >
            <div className={styles.titlesWrapper}>
                <span className={styles.title}>{title}</span>
            </div>

            <div className={styles.arrow}/>
            <div className={styles.modalWrapper}>
                {props.data.map((item, index) => {
                    return (
                        <div key={index} className={styles.item} data-value={item.value}
                             onClick={(event) => {
                                 setTitle(event.target.innerHTML);
                                 props.updateCriteria(parseInt(event.target.dataset.value), props.index ,props.name)
                                 if(props.category) {
                                     clearFieldForData(event.target.dataset.value)
                                 }
                             }}
                        >
                            {item.name}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ModalInputCriterion;