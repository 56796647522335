import React, {useEffect, useState} from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Showpiece(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const loopGallery = (imagesTable , url = null) => {
        let table = []
        if (!url) {
            for (let imageData in imagesTable) {
                table.push(imagesTable[imageData].file_name)
            }
        } else {
            for (let imageData in imagesTable) {
                table.push(url + imagesTable[imageData].file_name)
            }
        }
        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery))
    }, [props.data.gallery])

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        {props.data.fields ? (
                            <>
                                {props.data.fields.subtitle ? (
                                        <div className={styles.blockTextWrapper}>
                                            <span className={styles.blockTitle}>{translation.showpiece.subtitle}</span>
                                            <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.subtitle}`}}/>
                                        </div>
                                ) : null}
                                {props.data.fields.additional_title ? (
                                        <div className={styles.blockTextWrapper}>
                                            <span className={styles.blockTitle}>{translation.showpiece.additional_title}</span>
                                            <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.additional_title}`}}/>
                                        </div>
                                ) : null}
                                {props.data.fields.author ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.author}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.author}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.publication_date ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.publication_date}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.publication_date}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.publication_place ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.publication_place}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.publication_place}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.publisher ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.publisher}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.publisher}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.isbn ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.isbn}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.isbn}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.language ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.language}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.language}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.number_of_pages ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.number_of_pages}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.number_of_pages}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.illustrations ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.illustrations}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.illustrations}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.dimensions ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.dimensions}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.dimensions}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.description ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.description}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.description}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.subjects ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.subjects}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.subjects}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.persons_related ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.persons_related}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.persons_related}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.institutions_related ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.institutions_related}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.institutions_related}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.event_associated ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.event_associated}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.event_associated}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.content_type ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.content_type}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.content_type}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.media_type ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.media_type}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.media_type}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.carrier_type ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.carrier_type}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.carrier_type}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.signature ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.signature}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.signature}`}}/>
                                    </div>
                                ) : null}
                                {props.data.fields.nukat ? (
                                    <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.showpiece.nukat}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.fields.nukat}`}}/>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Showpiece;