export function fade(id,timeout = 1) {
    let element = document.getElementById(id);
    let op = 1;  // initial opacity
    let timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, timeout);
}

export function unFade(id,maxFade = 1, display = "block",timeout = 3) {
    let element = document.getElementById(id);
    let op = 0.1;  // initial opacity
    element.style.display = display;
    let timer = setInterval(function () {
        if (op >= 1){
            clearInterval(timer);
        } else if (op >= maxFade){
            clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1;
    }, timeout);
}