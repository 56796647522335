import React , { useState, useEffect } from 'react';
import axios from "axios";

import styles from './textPage.module.scss';
import {FadeOut, Loader} from "../../Components/Loader/loader";
import headerStyles from "../Header/header.module.scss";

function TextPage(props) {

    const [data , setDate] = useState(null)

    useEffect(() => {
        document.getElementById("loader").style.display = "flex"
        document.getElementById("loader").style.opacity = "1"
        if(props.link) {
            axios
                .get(props.link)
                .then(res => {
                    setDate(res.data)
                    FadeOut("loader");
                })
        }
    },[props.link])

    useEffect(() => {
        let header = document.getElementById("header")
        header.classList.remove(headerStyles.headerWrapped)
        header.classList.add(headerStyles.header)
    },[])

    return (
        <>
            <Loader/>
            {data ? (
                <div className={styles.wrapper}>
                    {data.title ? (
                        <span className={styles.title}>
                        {data.title}
                    </span>
                    ) : null}
                    {data.txt ? (
                        <div className={styles.text} dangerouslySetInnerHTML={{__html : `${data.txt}`}}/>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}

export default TextPage;


