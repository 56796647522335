import React, {useEffect, useState} from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Letter(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const loopGallery = (imagesTable ,url) => {
        let table = []

        for (let imageData in imagesTable) {
            table.push(url + imagesTable[imageData].file_name)
        }

        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.galleries, "https://cms.pmp.edu.pl/_plik/museum/letters/" + props.id + "/"))
    }, [props.data.galleries, props.id])

    const persons = () => {
        if(props.data.persons) {
            let names = ""
            for(let i = 0; i < props.data.persons.length; i++) {
                if (props.data.persons[i].id) {
                    if(props.data.persons[i].name) {
                        names =`${names} <a href="https://redirect.nifc.pl/person/${props.data.persons[i].id}/${lang}" target="_blank">${props.data.persons[i].name}</a>`
                    }
                } else {
                    if(props.data.persons[i].name) {
                        names = names + props.data.persons[i].name + " "
                    }
                }

                if(i < props.data.persons.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.name ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.name}`}}/>
                        ) : null}
                        {props.data.source ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.source}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.source}`}}/>
                            </div>
                        ) : null}
                        {props.data.type ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.type}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.type}`}}/>
                            </div>
                        ) : null}
                        {props.data.data_start ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.data_start}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.data_start}`}}/>
                            </div>
                        ) : null}
                        {props.data.data_stop ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.data_stop}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.data_stop}`}}/>
                            </div>
                        ) : null}
                        {props.data.data_txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.data_txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.data_txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.sender_name ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.sender_name}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.sender_name}`}}/>
                            </div>
                        ) : null}
                        {props.data.addressee_name ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.addressee_name}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.addressee_name}`}}/>
                            </div>
                        ) : null}
                        {props.data.list_numer ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.list_numer}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.list_numer}`}}/>
                            </div>
                        ) : null}
                        {props.data.original_name ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.original_name}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.original_name}`}}/>
                            </div>
                        ) : null}
                        {props.data.original_txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.original_txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.original_txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.date_txt_en ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.date_txt_en}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.date_txt_en}`}}/>
                            </div>
                        ) : null}
                        {props.data.audio_title ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.audio_title}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.audio_title}`}}/>
                            </div>
                        ) : null}
                        {props.data.id_album ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.id_album}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.id_album}`}}/>
                            </div>
                        ) : null}
                        {props.data.shelfmark ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.shelfmark}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.shelfmark}`}}/>
                            </div>
                        ) : null}
                        {props.data.summary ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.summary}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.summary}`}}/>
                            </div>
                        ) : null}
                        {props.data.rights_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.rights_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.rights_note}`}}/>
                            </div>
                        ) : null}
                        {props.data.general_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.general_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.general_note}`}}/>
                            </div>
                        ) : null}
                        { persons() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.author}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${persons()}`}}/>
                            </div>
                            : props.data.author ? (
                                    <div className={styles.blockTextWrapper}>
                                        <span className={styles.blockTitle}>{translation.letters.author}</span>
                                        <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author}`}}/>
                                    </div>
                                ) : null
                        }
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Letter;