import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

import Manuscript from "../../Components/ItemTypes/Manuscript/manuscript";
import Letter from "../../Components/ItemTypes/Letter/letter";
import Poster from "../../Components/ItemTypes/Poster/poster";
import Iconography from "../../Components/ItemTypes/Iconography/iconography";
import Bibliography from "../../Components/ItemTypes/Bibliography/bibliography";
import Museum from "../../Components/ItemTypes/Museum/museum";
import MusicalPrints from "../../Components/ItemTypes/MusicalPrint/musicalPrints";
import {FadeOut, Loader} from "../../Components/Loader/loader";
import Showpiece from "../../Components/ItemTypes/Showpiece/showpiece";
import Multimedia from "../../Components/ItemTypes/Multimedia/multimedia";
import styles from "../../Components/ItemTypes/itemTypes.module.scss";

function ItemDescription(props) {

    const [data, setDate] = useState(null);
    const [imageLink, setImageLink] = useState("")

    let { lang, type, id } = useParams();
    id = parseInt(id);

    useEffect(() => {
        switch (type) {
            case 'listy':
                //setLink(props.url + "index.php/letters/get_letter_by_id/" + id);
                break;
            case 'rekopisy':
                //setLink(props.url + "index.php/manuscripts/get_manuscript_by_id/" + id);
                break;
            case 'afisze-i-plakaty':
                //setLink(props.url + "index.php/poster/get_poster_by_id/" + id);
                break;
            case 'bibliografia':
                //setLink(props.url + "/index.php/bibliography/get_bibliography_by_id/" + id);
                break;
            case 'ikonografia':
                //setLink(props.url + "index.php/iconography/get_by_id/" + id);
                break;
            case 'muzeum-inne':
                //setLink(props.url + "index.php/museum2/get_other_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/others/" + id + "/")
                break;
            case 'rzezby-muzealne':
                //setLink(props.url + "index.php/museum2/get_sculpture_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/sculptures/" + id + "/")
                break;
            case 'rzemioslo-muzealne':
                //setLink(props.url + "index.php/museum2/get_craft_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/crafts/" + id + "/")
                break;
            case 'archiwa-muzealne':
                //setLink(props.url + "index.php/museum2/get_archive_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/archives/" + id + "/")
                break;
            case 'pocztowki-muzealne':
                //setLink(props.url + "index.php/museum2/get_postcard_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/postcards/" + id + "/")
                break;
            case 'obrazy-muzealne':
                //setLink(props.url + "index.php/museum2/get_painting_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/paintings/" + id + "/")
                break;
            case 'grafika-muzealna':
                //setLink(props.url + "index.php/museum2/get_graphic_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/graphics/" + id + "/")
                break;
            case 'fotografie-muzealne':
                //setLink(props.url + "index.php/museum2/get_photograph_by_id/" + id);
                setImageLink("https://cms.pmp.edu.pl/_plik/museum/photographs/" + id + "/")
                break;
            case 'egzemplarz-ksiazki':
                //setLink(props.url + "index.php/first_editions/get_musical_print_by_id/" + id);
                break;
            case 'audio':
                //setLink(props.url + "index.php/first_editions/get_musical_print_by_id/" + id);
                break;
            default:
                break;
        }
    }, [type, props.url, id])

    const linkType = () => {
        switch (type) {
            case 'listy':
                return props.url + "index.php/letters/get_letter_by_id/" + id + "?lang=" + lang;
            case 'rekopisy':
                return props.url + "index.php/manuscripts/get_manuscript_by_id/" + id + "?lang=" + lang;
            case 'afisze-i-plakaty':
                return props.url + "index.php/poster/get_poster_by_id/" + id + "?lang=" + lang;
            case 'bibliografia':
                return props.url + "index.php/bibliography/get_bibliography_by_id/" + id + "?lang=" + lang;
            case 'zbiory-fotograficzne':
                return props.url + "index.php/iconography/get_by_id/" + id + "?lang=" + lang;
            case 'muzeum-inne':
                return props.url + "index.php/museum2/get_other_by_id/" + id + "?lang=" + lang;
            case 'rzezby-muzealne':
                return props.url + "index.php/museum2/get_sculpture_by_id/" + id + "?lang=" + lang;
            case 'rzemioslo-muzealne':
                return props.url + "index.php/museum2/get_craft_by_id/" + id + "?lang=" + lang;
            case 'archiwa-muzealne':
                return props.url + "index.php/museum2/get_archive_by_id/" + id + "?lang=" + lang;
            case 'pocztowki-muzealne':
                return props.url + "index.php/museum2/get_postcard_by_id/" + id + "?lang=" + lang;
            case 'obrazy-muzealne':
                return props.url + "index.php/museum2/get_painting_by_id/" + id + "?lang=" + lang;
            case 'grafika-muzealna':
                return props.url + "index.php/museum2/get_graphic_by_id/" + id + "?lang=" + lang;
            case 'fotografie-muzealne':
                return props.url + "index.php/museum2/get_photograph_by_id/" + id + "?lang=" + lang;
            case 'druki-muzyczne':
                return props.url + "index.php/first_editions/get_musical_print_by_id/" + id + "?lang=" + lang;
            case 'egzemplarz-ksiazki':
                return props.url + "index.php/showpiece/get_by_id/" + id + "?lang=" + lang;
            case 'audio':
                return props.url + "index.php/discography2/get_performance_by_id/" + id + "?lang=" + lang;
            default:
                return ""
        }
    }

    const [link,] = useState(linkType())

    useEffect(() => {
        if(type === "listy") {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            axios
                .post(link,`params={"return_galleries":true,"return_techniques":true,"return_materials":true}`,config)
                .then(res => {
                    setDate(res.data)
                    if(!res.data) {
                        window.location.href ="/"
                    }
                    FadeOut("loader");
                })
        } else if (type === "bibliografia") {
            axios
                .post(link, `data={"return_gallery":true,"return_journalnumber": "${id}"}`)
                .then(res => {
                    setDate(res.data)
                    if (!res.data) {
                        window.location.href = "/"
                    }
                    FadeOut("loader");
                })
        } else {
            axios
                .post(link)
                .then(res => {
                    setDate(res.data)
                    if(!res.data) {
                        window.location.href ="/"
                    }
                    FadeOut("loader");
                })
        }
    },[link, type, id])

    const elementType = () => {
        switch (type) {
            case 'rekopisy':
                return (
                    <Manuscript data={data} id={id} type={type}/>
                )
            case 'listy':
                return (
                    <Letter data={data} id={id} type={type}/>
                )
            case 'afisze-i-plakaty':
                return (
                    <Poster data={data} id={id} type={type}/>
                )
            case 'bibliografia':
                return (
                    <Bibliography data={data} id={id} type={type}/>
                )
            case 'zbiory-fotograficzne':
                return (
                    <Iconography data={data} id={id} type={type}/>
                )
            case 'druki-muzyczne':
                return (
                    <MusicalPrints data={data} id={id} type={type}/>
                )
            case 'egzemplarz-ksiazki':
                return (
                    <Showpiece data={data} id={id} type={type}/>
                )
            case 'audio':
                return (
                    <Multimedia data={data} id={id} type={type}/>
                )
            default:
                return (
                    <Museum data={data} id={id} type={type} imageLink={imageLink}/>
                )
        }
    }

    return (
        <>
            <Loader/>
            {data ? (
                <div>
                    <div className={styles.ueLogo}/>
                    {elementType()}
                </div>
            ) : null}
        </>
    )
}

export default ItemDescription;