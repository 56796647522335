import React, { useEffect, useState}  from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Manuscript(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const loopGallery = (imagesTable ,url) => {
        let table = []

        for (let imageData in imagesTable) {
            table.push(url + imagesTable[imageData].file_name)
        }

        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery, "https://cms.pmp.edu.pl/_plik/museum/manuscripts/" + props.id + "/"))
    }, [props.data.gallery, props.id])

    const composition = () => {
        if(props.data.compositions.length) {
            let names = ""
            for(let i = 0; i < props.data.compositions.length; i++) {
                let name = ""
                if(props.data.compositions[i].title) {
                    name = name + props.data.compositions[i].title + " "
                }
                if(props.data.compositions[i].opus) {
                    name = name + props.data.compositions[i].opus
                }
                if(props.data.compositions[i].id) {
                    names =`${names} <a href="https://redirect.nifc.pl/composition/${props.data.compositions[i].id}/${lang}" target="_blank">${name}</a>`
                } else {
                    names =`${names} ${name}`
                }

                if(i < props.data.compositions.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        {props.data.manuscripts_category ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.manuscripts_category}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.manuscripts_category}`}}/>
                            </div>
                        ) : null}
                        {props.data.signature ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.signature}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.signature}`}}/>
                            </div>
                        ) : null}
                        {props.data.headline ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.headline}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.headline}`}}/>
                            </div>
                        ) : null}
                        {props.data.txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.composer ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.composer}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.composer}`}}/>
                            </div>
                        ) : null}
                        {props.data.place ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.place}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.place}`}}/>
                            </div>
                        ) : null}
                        {props.data.country ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.country}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.country}`}}/>
                            </div>
                        ) : null}
                        {props.data.size ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.size}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.size}`}}/>
                            </div>
                        ) : null}
                        {props.data.number_of_pages ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.number_of_pages}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.number_of_pages}`}}/>
                            </div>
                        ) : null}
                        {props.data.shelfmark ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.shelfmark}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.shelfmark}`}}/>
                            </div>
                        ) : null}
                        {props.data.summary ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.summary}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.summary}`}}/>
                            </div>
                        ) : null}
                        {props.data.rights_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.rights_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.rights_note}`}}/>
                            </div>
                        ) : null}
                        {props.data.general_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.general_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.general_note}`}}/>
                            </div>
                        ) : null}
                        {props.data.copyists.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.copyists.copyists}</span>
                                {
                                    props.data.copyists.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.copyists.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.dedications.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.dedications.dedications}</span>
                                {
                                    props.data.dedications.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.dedications.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.owners.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.owners.owners}</span>
                                {
                                    props.data.owners.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.name ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.owners.name}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        { composition() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.compositions.compositions}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${composition()}`}}/>
                            </div>
                            : props.data.compositions.length ? (
                                <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.manuscripts.compositions.compositions}</span>
                                    {
                                        props.data.compositions.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item.name ? (
                                                        <div className={styles.box}>
                                                            <span className={styles.boxTitle}>{translation.manuscripts.compositions.name}</span>
                                                            <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                        </div>
                                                    ) : null }
                                                    {item.title ? (
                                                        <div className={styles.box}>
                                                            <span className={styles.boxTitle}>{translation.manuscripts.compositions.title}</span>
                                                            <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                        </div>
                                                    ) : null }
                                                    {item.opus ? (
                                                        <div className={styles.box}>
                                                            <span className={styles.boxTitle}>{translation.manuscripts.compositions.opus}</span>
                                                            <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.opus}`}}/>
                                                        </div>
                                                    ) : null }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {props.data.first_editions.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.first_editions.first_editions}</span>
                                {
                                    props.data.first_editions.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.title ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.first_editions.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                                {item.country ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.first_editions.country}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.country}`}}/>
                                                    </div>
                                                ) : null }
                                                {item.publisher ? (
                                                    <div className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.first_editions.publisher}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.publisher}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                        {props.data.facsimile.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.manuscripts.facsimile.facsimile}</span>
                                {
                                    props.data.facsimile.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.title ? (
                                                    <div key={index} className={styles.box}>
                                                        <span className={styles.boxTitle}>{translation.manuscripts.facsimile.title}</span>
                                                        <span className={styles.boxText} dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                                    </div>
                                                ) : null }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null }
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Manuscript;