import React , { useEffect, useState }  from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Poster(props) {

    const [gallery, setGallery] = useState([])

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    useEffect(() => {
        setGallery([props.data.obverse_img, props.data.reverse_img ])
    }, [props.data.obverse_img, props.data.reverse_img])

    /*const persons = () => {
        if(props.data.persons) {
            let names = ""
            for(let i = 0; i < props.data.persons.length; i++) {
                if (props.data.persons[i].id) {
                    if(props.data.persons[i].name) {
                        names =`${names} <a href="https://redirect.nifc.pl/person/${props.data.persons[i].id}/${lang}" target="_blank">${props.data.persons[i].name}</a>`
                    }
                } else {
                    if(props.data.persons[i].name) {
                        names = names + props.data.persons[i].name + " "
                    }
                }

                if(i < props.data.persons.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    const institutions = () => {
        if(props.data.institutions) {
            let names = ""
            for(let i = 0; i < props.data.institutions.length; i++) {
                if (props.data.institutions[i].id) {
                    if(props.data.institutions[i].name) {
                        names =`${names} <a href="https://redirect.nifc.pl/organisation/${props.data.institutions[i].id}/${lang}" target="_blank">${props.data.institutions[i].name}</a>`
                    }
                } else {
                    if(props.data.institutions[i].name) {
                        names = names + props.data.institutions[i].name + " "
                    }
                }

                if(i < props.data.institutions.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }*/

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        {props.data.subtitle ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.subtitle}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.subtitle}`}}/>
                            </div>
                        ) : null}
                        {props.data.author ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.author}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author}`}}/>
                            </div>
                        ) : null}
                        {props.data.co_author ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.co_author}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.co_author}`}}/>
                            </div>
                        ) : null}
                        {props.data.publication_place ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.publication_place}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publication_place}`}}/>
                            </div>
                        ) : null}
                        {props.data.publisher ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.publisher}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publisher}`}}/>
                            </div>
                        ) : null}
                        {props.data.publication_date_txt ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.publication_date_txt}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.publication_date_txt}`}}/>
                            </div>
                        ) : null}
                        {props.data.printing ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.printing}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.printing}`}}/>
                            </div>
                        ) : null}
                        {props.data.dimensions ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.dimensions}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.dimensions}`}}/>
                            </div>
                        ) : null}
                        {props.data.content_type ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.content_type}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.content_type}`}}/>
                            </div>
                        ) : null}
                        {props.data.media_type ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.media_type}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.media_type}`}}/>
                            </div>
                        ) : null}
                        {props.data.carrier_type ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.carrier_type}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.carrier_type}`}}/>
                            </div>
                        ) : null}
                        {props.data.description ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.description}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.description}`}}/>
                            </div>
                        ) : null}
                        {props.data.subject ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.subjects}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.subject}`}}/>
                            </div>
                        ) : null}
                        {props.data.genre_or_form ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.genre_or_form}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.genre_or_form}`}}/>
                            </div>
                        ) : null}
                        {props.data.language ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.language}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.language}`}}/>
                            </div>
                        ) : null}
                        {props.data.signature ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.signature}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.signature}`}}/>
                            </div>
                        ) : null}
                        {props.data.nukat ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.nukat}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.nukat}`}}/>
                            </div>
                        ) : null}



                        {/*{props.data.event_associtated ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.event_associtated}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.event_associtated}`}}/>
                            </div>
                        ) : null}

                        { persons() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.posters.persons_related}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${persons()}`}}/>
                            </div>
                            : props.data.author ? (
                                <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.posters.persons_related}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.persons_related}`}}/>
                                </div>
                            ) : null
                        }

                        {institutions() ?
                            <div className={styles.blockTextWrapper}>
                                <span
                                    className={styles.blockTitle}>{translation.posters.institutions_related}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html: `${institutions()}`}}/>
                            </div>
                            : props.data.institutions_related ? (
                                <div className={styles.blockTextWrapper}>
                                    <span
                                        className={styles.blockTitle}>{translation.posters.institutions_related}</span>
                                    <span className={styles.text}
                                          dangerouslySetInnerHTML={{__html: `${props.data.institutions_related}`}}/>
                                </div>
                            ) : null
                        }*/}
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Poster;