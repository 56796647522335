import React, { useEffect, useState } from 'react';
import {useHistory, Link, useParams} from "react-router-dom";

import Menu from "../../Menu/menu";
import translation from "../../Helpers/translation";

import styles from "../itemTypes.module.scss"

function Museum(props) {

    let history = useHistory();
    function handleClick() {
        history.goBack()
    }

    let { lang, type, id } = useParams();

    const [gallery, setGallery] = useState([])

    const loopGallery = (imagesTable , url = null) => {
        let table = []
        if (!url) {
            for (let imageData in imagesTable) {
                table.push(imagesTable[imageData].file_name)
            }
        } else {
            for (let imageData in imagesTable) {
                table.push(url + imagesTable[imageData].file_name)
            }
        }
        return table
    }

    useEffect(() => {
        setGallery(loopGallery(props.data.gallery, props.imageLink))
    }, [ props.data.gallery, props.imageLink])

    const persons = () => {
        if(props.data.persons) {
            let names = ""
            for(let i = 0; i < props.data.persons.length; i++) {
                if (props.data.persons[i].id) {
                    if(props.data.persons[i].name) {
                        names =`${names} <a href="https://redirect.nifc.pl/person/${props.data.persons[i].id}/${lang}" target="_blank">${props.data.persons[i].name}</a>`
                    }
                } else {
                    if(props.data.persons[i].name) {
                        names = names + props.data.persons[i].name + " "
                    }
                }

                if(i < props.data.persons.length - 1) {
                    names = names + ", "
                }
            }
            return `${names}`
        } else {
            return null
        }
    }

    return (
        props.data ? (
            <div className={styles.wrapper}>
                <div className={`${styles.left} ${gallery ? "" : styles.leftSmall}`} id={"left"}>
                    <div className={styles.leftMenu}>
                        <span className={styles.back} onClick={handleClick}/>
                        <Menu/>
                    </div>
                    <div className={styles.imagesWrapper}>
                        {gallery ? (
                            gallery.map((item, index) => {
                                return (
                                    <Link key={index} to={`/${lang}/galeria-zasobu/${type}/${id}/${index}`}>
                                        <img loading="lazy" src={gallery[index]} className={styles.image} alt={props.data.alt_photo}/>
                                    </Link>
                                )
                            })
                        ) : null}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.right} id={"right"}>
                        <span className={styles.mainTitle} >{translation.itemTitle} - {translation.typeDescription[props.type]}</span>
                        {props.data.title ? (
                            <span className={styles.title} dangerouslySetInnerHTML={{__html : `${props.data.title}`}}/>
                        ) : null}
                        {props.data.shelfmark ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.shelfmark}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.shelfmark}`}}/>
                            </div>
                        ) : null}
                        {props.data.summary ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.summary}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.summary}`}}/>
                            </div>
                        ) : null}
                        {props.data.rights_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.rights_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.rights_note}`}}/>
                            </div>
                        ) : null}
                        {props.data.general_note ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.general_note}</span>
                                <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.general_note}`}}/>
                            </div>
                        ) : null}
                        { persons() ?
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.letters.author}</span>
                                <div className={styles.text} dangerouslySetInnerHTML={{__html : `${persons()}`}}/>
                            </div>
                            : props.data.author ? (
                                <div className={styles.blockTextWrapper}>
                                    <span className={styles.blockTitle}>{translation.letters.author}</span>
                                    <span className={styles.text} dangerouslySetInnerHTML={{__html : `${props.data.author}`}}/>
                                </div>
                            ) : null
                        }
                        {props.data.materials.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.materials.materials}</span>
                                <div className={styles.box2}>
                                    {
                                        props.data.materials.map((item, index) => {
                                            return (
                                                index === props.data.materials.length - 1 ? (
                                                    <div key={index}>
                                                        {item.name ? (
                                                            <span className={styles.text} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    <div key={index}>
                                                        {item.name ? (
                                                            <>
                                                                <span className={styles.text} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                                <span className={styles.text} style={{marginLeft : "unset", marginRight: "5px"}}>, </span>
                                                            </>

                                                        ) : null}
                                                    </div>
                                                )
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : null }
                        {props.data.techniques.length ? (
                            <div className={styles.blockTextWrapper}>
                                <span className={styles.blockTitle}>{translation.muzeum.techniques.techniques}</span>
                                <div className={styles.box2}>
                                    {
                                        props.data.techniques.map((item, index) => {
                                            return (
                                                index === props.data.techniques.length - 1 ? (
                                                    <div key={index}>
                                                        {item.name ? (
                                                            <span className={styles.text} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                        ) : null}
                                                    </div>
                                                    ) : (
                                                        <div key={index}>
                                                            {item.name ? (
                                                                <>
                                                                    <span className={styles.text} dangerouslySetInnerHTML={{__html: `${item.name}`}}/>
                                                                    <span className={styles.text} style={{marginLeft : "unset", marginRight: "5px"}}>, </span>
                                                                </>

                                                            ) : null}
                                                        </div>
                                                    )
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : null }
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Museum;