import React from 'react';

import text from '../../assets/translation/translation.json'
import styles from './banner.module.scss';
import {Link} from "react-router-dom";

function Header() {

    const showHideMenu = (e) => {
        let menu_transform = document.getElementById("menu").style.transform;
        if (menu_transform === "none") {
            document.getElementById("menu").style.transform = "translate(-101%, 0)";
            document.getElementById("burger").checked = false;
        } else {
            document.getElementById("menu").style.transform = "none";
            document.getElementById("burger").checked = true;
        }

        if(e.target.href) {
            window.scroll(0,0)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={`${styles.headerWrapped} ${styles.header}`} id={"header"}>
                <nav className={styles.navigation}>
                    <div className={styles.menuToggle}>
                        <input type={"checkbox"} onClick={showHideMenu} id={"burger"}/>
                        <span/>
                        <span/>
                        <span/>
                        <ul className={styles.menu} id={"menu"}>
                            <li>
                                <Link to={`/${"pl"}`} target="_blank">{text["en"].menu.collections}</Link>
                            </li>
                            <li>
                                <Link to={`/${"pl"}/o-projekcie`} target="_blank">{text["en"].menu.about}</Link>
                            </li>
                            <li>
                                <Link to={`/${"pl"}/polityka-prywatnosci`} target="_blank">{text["en"].menu.policy}</Link>
                            </li>
                            <li>
                                <Link to={`/${"pl"}/kontakt`} target="_blank">{text["en"].menu.contact}</Link>
                            </li>
                        </ul>
                        <div className={styles.menuHider}/>
                    </div>
                </nav>
                <p className={styles.mainTitle}>{text["en"].mainPageTitle}</p>
                <p className={styles.title}>{text["en"].mainPageSubtitle}</p>
            </div>
        </div>
    );
}

export default Header;