import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory, useParams } from "react-router-dom";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon
} from 'react-share';

import Menu from "../../Components/Menu/menu";

import translation from "../../Components/Helpers/translation";
import usePrevious from "../../Components/Helpers/usePrevious";
import useWindowSize from "../../Components/Helpers/useWindowSize";

import styles from "./gallery.module.scss"
import {FadeOut, Loader} from "../../Components/Loader/loader";

function Gallery(props) {

    let slug = require('slug')

    let history = useHistory();

    function handleClick() {
        history.goBack()
    }

    let { lang ,type, id ,number } = useParams();
    id = parseInt(id);
    number = number.toString()
    type = type.toString()

    const [data, setDate] = useState(null);
    const prevData = usePrevious(data)
    const [positionX, ] = useState(50);
    const [positionY, ] = useState(50);
    const [changeX, setChangeX] = useState(0);
    const [changeY, setChangeY] = useState(0);
    const [zoom, setZoom] = useState(window.innerWidth > 700 ? 1 : 0.4);
    const [moveActive, setMoveActive] = useState();
    const [deltaX, setDeltaX] = useState(0);
    const [deltaY, setDeltaY] = useState(0);
    const [mousePositionX, setMousePositionX] = useState(0);
    const [mousePositionY, setMousePositionY] = useState(0);
    const [activeElement, setActiveElement] = useState(0);
    const [isNext, setIsNext] = useState(false);
    const [isPrev, setIsPrev] = useState(false);
    const [gallery, setGallery] = useState([])
    const [title, setTitle] = useState("")
    let zoomSpeed = 0.15;
    let deltaMousePositionX = 0;
    let deltaMousePositionY = 0;
    const size = useWindowSize();


    if(window.innerWidth < 401) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    const loopGallery = (imagesTable , url = null) => {
        let table = []
        if (!url) {
            for (let imageData in imagesTable) {
                table.push([imagesTable[imageData].file_name, imagesTable[imageData].img_urls])
            }
        } else {
            for (let imageData in imagesTable) {
                table.push([url + imagesTable[imageData].file_name, imagesTable[imageData].img_urls])

            }
        }
        return table
    }
    
    useEffect(() => {
        switch (type) {
            case 'listy':
                if (data) {
                    setGallery(loopGallery(data.galleries, "https://cms.pmp.edu.pl/_plik/museum/letters/" + id + "/"))
                    setTitle(data.name)
                }
                break;
            case 'rekopisy':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/manuscripts/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'afisze-i-plakaty':
                if (data) {
                    setGallery([[data.obverse_img,], [data.reverse_img,]])
                    setTitle(data.title)
                }
                break;
            case 'bibliografia':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_foto/bibliography/doc_galleries/"))
                    setTitle(data.title)
                }
                break;
            case 'zbiory-fotograficzne':
                if (data) {
                    setGallery(loopGallery(data.gallery))
                    setTitle(data.title)
                }
                break;
            case 'muzeum-inne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/others/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'rzezby-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/sculptures/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'rzemioslo-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/crafts/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'archiwa-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/archives/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'pocztowki-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/postcards/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'obrazy-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/paintings/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'grafika-muzealna':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/graphics/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'fotografie-muzealne':
                if (data) {
                    setGallery(loopGallery(data.gallery ,"https://cms.pmp.edu.pl/_plik/museum/photographs/" + id + "/"))
                    setTitle(data.title)
                }
                break;
            case 'druki-muzyczne':
                if (data) {
                    setGallery(loopGallery(data.gallery))
                    setTitle(data.title)

                }
                break;
            case 'egzemplarz-ksiazki':
                if (data) {
                    setGallery(loopGallery(data.gallery))
                    setTitle(data.title)
                }
                break;
            default:
                break;
        }
    },[data, id, number, props.url, title, type])

    const linkType = () => {
        switch (type) {
            case 'listy':
                return props.url + "index.php/letters/get_letter_by_id/" + id + "?lang=" + lang;
            case 'rekopisy':
                return props.url + "index.php/manuscripts/get_manuscript_by_id/" + id + "?lang=" + lang;
            case 'afisze-i-plakaty':
                return props.url + "index.php/poster/get_poster_by_id/" + id + "?lang=" + lang;
            case 'bibliografia':
                return props.url + "index.php/bibliography/get_bibliography_by_id/" + id + "?lang=" + lang;
            case 'zbiory-fotograficzne':
                return props.url + "index.php/iconography/get_by_id/" + id + "?lang=" + lang;
            case 'muzeum-inne':
                return props.url + "index.php/museum2/get_other_by_id/" + id + "?lang=" + lang;
            case 'rzezby-muzealne':
                return props.url + "index.php/museum2/get_sculpture_by_id/" + id + "?lang=" + lang;
            case 'rzemioslo-muzealne':
                return props.url + "index.php/museum2/get_craft_by_id/" + id + "?lang=" + lang;
            case 'archiwa-muzealne':
                return props.url + "index.php/museum2/get_archive_by_id/" + id + "?lang=" + lang;
            case 'pocztowki-muzealne':
                return props.url + "index.php/museum2/get_postcard_by_id/" + id + "?lang=" + lang;
            case 'obrazy-muzealne':
                return props.url + "index.php/museum2/get_painting_by_id/" + id + "?lang=" + lang;
            case 'grafika-muzealna':
                return props.url + "index.php/museum2/get_graphic_by_id/" + id + "?lang=" + lang;
            case 'fotografie-muzealne':
                return props.url + "index.php/museum2/get_photograph_by_id/" + id + "?lang=" + lang;
            case 'druki-muzyczne':
                return props.url + "index.php/first_editions/get_musical_print_by_id/" + id + "?lang=" + lang;
            case 'egzemplarz-ksiazki':
                return props.url + "index.php/showpiece/get_by_id/" + id + "?lang=" + lang;
            default:
                return ""
        }
    }

    const [link,] = useState(linkType())

    const escKeydown = (event) => {
        if(event.keyCode === 27) {
            history.push(`/${lang}/opis-zasobu/${type}/${id}-${slug(title ? title : "")}`)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', escKeydown);
        return () => window.removeEventListener('keydown', escKeydown);
    })

    useEffect(() => {
        if(type === "listy") {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            axios
                .post(link,`params={"return_galleries":true,"return_techniques":true,"return_materials":true}`,config)
                .then(res => {
                    setDate(res.data)
                    if(!res.data) {
                        window.location.href ="/"
                    }
                    FadeOut("loader");
                })
        } else if (type === "bibliografia") {
            axios
                .post(link, `data={"return_gallery":true}`)
                .then(res => {
                    setDate(res.data)
                    if (!res.data) {
                        window.location.href = "/"
                    }
                    FadeOut("loader");
                })
        } else {
            axios
                .post(link)
                .then(res => {
                    setDate(res.data)
                    if(!res.data) {
                        window.location.href ="/"
                    }
                    FadeOut("loader");
                })
        }
    },[link, type])

    useEffect(() => {
        setActiveElement(number)
    },[number])

    useEffect(() => {
        if (gallery) {
            setIsNext(!!gallery[activeElement + 1])
            setIsPrev(!!gallery[activeElement - 1])
            if(prevData !== data) {
                setActiveElement(number ? parseInt(number) : 0)
            }
        }
    },[activeElement, data, gallery, number, prevData])

    const prevImg= () => {
        setActiveElement(activeElement - 1)
    }

    const nextImg= () => {
        setActiveElement( activeElement + 1)
    }

    useEffect(() => {
        const handleKayPress = (event) => {
            if(event.keyCode === 37 && isPrev) {
                setActiveElement(activeElement - 1)
            } else if (event.keyCode === 39 && isNext) {
                setActiveElement( activeElement + 1)
            }
        }
        document.addEventListener('keydown', handleKayPress);
        return () => document.removeEventListener('keydown', handleKayPress);
    })

    const resizeImage = e => {
        if(e.deltaY > 0){
            if(zoom > 0.3) {
                setZoom(zoom - zoomSpeed)
            }
        } else {
            setZoom(zoom + zoomSpeed)
        }
    }

    const zoomIn = () => {
        setZoom(zoom + zoomSpeed)
    }

    const zoomOut = () => {
        if(zoom > 0.3) {
            setZoom(zoom - zoomSpeed)
        }
    }

    const movingImage = e => {
        if(moveActive){
            if(e.type === 'touchmove') {
                deltaMousePositionX = mousePositionX - e.touches[0].clientX;
                deltaMousePositionY = mousePositionY - e.touches[0].clientY;
            } else {
                deltaMousePositionX = mousePositionX - e.clientX;
                deltaMousePositionY = mousePositionY - e.clientY;
            }

            setChangeX(deltaX + deltaMousePositionX);
            setChangeY(deltaY + deltaMousePositionY)
        }
    }

    const setInitialHandler = (x, y) => {
        setMousePositionX(x)
        setMousePositionY(y)
    }

    const startMovingImage = (e) => {
        setMoveActive(true);
        if(e.type === 'touchstart') {
            setInitialHandler(e.touches[0].clientX, e.touches[0].clientY);
            document.getElementsByTagName("body")[0].style.overscrollBehavior = "contain"
        } else {
            setInitialHandler(e.clientX, e.clientY);
        }
    }

    const stopMovingImage = (e) => {
        setMoveActive(false);
        setDeltaX(changeX);
        setDeltaY(changeY);
        document.getElementsByTagName("body")[0].style.overscrollBehavior = "unset"
    }

    const titles = () => {
        if(type === "egzemplarz-ksiazki") {
            let names = ""
            if(data.authors.length) {
                for(let i = 0; i < data.authors.length; i++) {
                    if(data.authors[i].first_name) {
                        names = names + data.authors[i].first_name + " "
                    }
                    if(data.authors[i].last_name) {
                        names = names + data.authors[i].last_name
                    }
                    if(i < data.authors.length - 1) {
                        names = names + ", "
                    }
                }
            }
            return `${title} / ${names}`
        } else {
            return title
        }
    }

    const resizeSocialIcons = () => {
        if(size.width >= 801 ) {
            return 55
        } else {
            return 38
        }
    }

    const openDownload = () => {
        /** Slide down. */
        const container = document.getElementById("downloadWrapper")

        if(!container.classList.contains(styles.downloadWrapperActive)) {
            container.classList.add(styles.downloadWrapperActive)
            container.style.height = "auto"

            let height = container.clientHeight + "px"

            container.style.height = "0px"

            setTimeout(() => {
                container.style.height = height
            }, 0)
            /** Slide up. */
        } else {
            container.style.height = container.clientHeight + "px"
            container.style.height = "0px"

            container.addEventListener('transitionend', () => {
                container.classList.remove(styles.downloadWrapperActive)
            }, {once: true})
        }
    }

    useEffect(() => {
        const resizer = () => {
            if(document.getElementById("downloadWrapper")) {
                const container = document.getElementById("downloadWrapper")
                container.style.height = "auto"
                setTimeout(() => {
                    container.style.height = container.clientHeight + "px"
                }, 100)
            }
        }

            window.addEventListener('resize', resizer);
            return () => window.removeEventListener('resize', resizer);
    },[])

    return (
        <>
            <Loader/>
            {data ? (
                <div className={styles.wrapper}>
                    <div className={styles.gallery} onWheelCapture={resizeImage} onMouseDown={startMovingImage} onMouseUp={stopMovingImage} onTouchStart={startMovingImage} onTouchEnd={stopMovingImage}>
                        <div className={styles.shadow}/>
                        <div className={styles.shortDescriptionWrapper}>
                            {link !== '' && data ? (
                                <div className={styles.content}>
                                    <span className={styles.title} dangerouslySetInnerHTML={{__html : `${titles() ? titles() : ""}`}}/>
                                    <Link to={`/${lang}/opis-zasobu/${type}/${id}-${slug(title ? title : "")}`}>
                                        <span>{translation.reedMore}</span>
                                        <span className={styles.arrow}/>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                        <div className={styles.rightMenu}>
                            <div className={styles.zoom} onClick={zoomIn}/>
                            <div className={`${styles.unZoom} ${ zoom > 0.3 ? "" : styles.disable}`} onClick={() => zoom > 0.3 ? zoomOut() :null}/>

                            {gallery[activeElement] ?
                                <>
                                    {gallery[activeElement][1] || data.pdf ?
                                        <div className={styles.downloadContainer}>
                                            <div className={styles.download} onClick={openDownload}/>
                                            <div className={styles.downloadWrapper} id={"downloadWrapper"}>
                                                { gallery[activeElement][1] ?
                                                    Object.keys(gallery[activeElement][1]).map((item, index) => {

                                                        let types = ['obj']
                                                        Object.keys(gallery[activeElement][1]).map((item, index) => {
                                                            if(item === 'jpg') {
                                                                types.push('jpg')
                                                            }
                                                            return null;
                                                        })
                                                        if(!types.includes('jpg')) {
                                                            types.push('medium_size')
                                                        }
                                                        return (
                                                            types.includes(item)
                                                                ? <a href={gallery[activeElement][1][item]} key={index} className={styles.downloadButton} rel="noreferrer">{translation.gallery[item]}</a>
                                                                : null
                                                        )
                                                    })
                                                    : null
                                                }
                                                { data.pdf ?
                                                    <a href={data.pdf} className={styles.downloadButton} rel="noreferrer">{translation.gallery.pdf}</a>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    : null}
                                </>
                                :null
                            }
                        </div>
                        <div className={styles.leftMenu}>
                            <span className={styles.back} onClick={handleClick}/>
                            <Menu/>
                            <div className={styles.share}>
                                <div className={styles.ueLogo}/>
                                <FacebookShareButton url={window.location.href}>
                                    <FacebookIcon size={resizeSocialIcons()} />
                                </FacebookShareButton>

                                <TwitterShareButton url={window.location.href}>
                                    <TwitterIcon size={resizeSocialIcons()} />
                                </TwitterShareButton>

                                <LinkedinShareButton url={window.location.href}>
                                    <LinkedinIcon size={resizeSocialIcons()} />
                                </LinkedinShareButton>
                            </div>
                        </div>
                        <div style={{display: "none"}}>
                            {gallery[activeElement] ?
                                gallery.map((item, index) => {
                                    return (
                                        <img key={index} src={item[0]} alt={""}/>
                                    )
                                })
                                : null
                            }
                        </div>
                        <div className={styles.imageWrapper}>
                            {gallery[activeElement] ? (
                                <div onMouseMove={event => moveActive ? movingImage(event) : null}
                                     onTouchMove={event => moveActive ? movingImage(event) : null}
                                     className={styles.image} id={"zoom-image"}
                                     style={{ backgroundImage: `url(${gallery[activeElement][0]})`,
                                         backgroundSize: `auto ${100*zoom}%`,
                                         backgroundPositionY: `calc(${positionY}% - ${changeY}px)`,
                                         backgroundPositionX: `calc(${positionX}% - ${changeX}px)`
                                     }}
                                />
                            ) : null}
                            <button disabled={!isPrev} onClick={prevImg} className={`${styles.navBtn} ${styles.left}`}/>
                            <button disabled={!isNext} onClick={nextImg} className={`${styles.navBtn} ${styles.right}`}/>
                        </div>
                    </div>
                </div>
            ) : null}
        </>

    )
}

export default Gallery;