import React , { useState, useEffect } from 'react';
import axios from "axios";
import {useParams, useLocation} from "react-router-dom";

import List from "../../Components/List/list";

import styles from './listWrapper.module.scss';
import headerStyles from '../Header/header.module.scss';
import {FadeOut, Loader} from "../../Components/Loader/loader";
import AdvancedInputs from "../../Components/AdvancedInputs/advancedInputs";
import queryString from 'query-string';


function AdvancedSearchWrapper(props) {

	let { lang } = useParams();
	const location = useLocation();
	const [prevLocation, setPrevLocation] = useState(location)
	let queryParams = queryString.parse(location.search)
	let paramKeyword = queryParams["keyword"];
	let paramType = queryParams["type"];
	let paramCriteria = queryParams["search_fields"];

	const [data, setDate] = useState(null);
	const [offset, ] = useState(20);
	const [scrollPosition, setScrollPosition] = useState(window.scrollY)
	const [counter, setCounter] = useState(0)
	const [more, setMore] = useState(true)
	const [scrollHeight, setScrollHeight] = useState(0)
	const [scrollPercent, setScrollPercent] =  useState(0)
	const [imagesNull, setImagesNull] = useState(0)
	const [historyOffset, setHistoryOffset] = useState(sessionStorage.getItem("offset") ? parseInt(sessionStorage.getItem("offset")) :  20)
	const [link, setLink] = useState(props.url + `index.php/Search_engine/search/0/${historyOffset}?lang=` + lang + "&api_key=555b2feb468c0360cd705e6cffd28d9d");

	const handleScroll = () => {
		setTimeout(() => {
			setScrollPosition(window.scrollY)
		}, 1000);
	}

	useEffect(() => {
		if(counter === 0) {
			if(historyOffset > 20) {
				setCounter(historyOffset / 20 )
			} else {
				setCounter(1)
			}
			axios
				.post(link, `data={"keyword":"${paramKeyword ? paramKeyword : ''}","type":"${paramType ? paramType : ''}","search_fields":${paramCriteria ? paramCriteria : []}}`)
				.then(res => {
					setDate(res.data);
					FadeOut("loader");
					setMore(res.data.length === historyOffset)
					if(res.data.length === historyOffset) {
						if(historyOffset > 20) {
							setLink(props.url + `index.php/Search_engine/search/${historyOffset}/${offset}?lang=` + lang + "&api_key=555b2feb468c0360cd705e6cffd28d9d")
							sessionStorage.removeItem("offset")
						} else {
							setLink(props.url + `index.php/Search_engine/search/${offset}/${offset}?lang=` + lang + "&api_key=555b2feb468c0360cd705e6cffd28d9d")
							sessionStorage.removeItem("offset")
						}
					}
					setScrollHeight((document.documentElement.scrollHeight - window.innerHeight))
					let nullCounter = 0
					for (const item in res.data) {
						if(res.data[item].image === null) {
							nullCounter++
						}
					}
					setImagesNull(nullCounter)
				})
		}
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	},[counter, historyOffset, lang, link, offset, paramKeyword, paramType, props.url, paramCriteria])

	useEffect(() => {
		if((scrollPercent >= 70 && more && counter > 0 && data ) || (imagesNull  > 10 && counter < 6)) {
			setMore(false)
			setImagesNull(0)
			axios
				.post(link, `data={"keyword":"${paramKeyword ? paramKeyword : ''}","type":"${paramType ? paramType : ''}","search_fields":${paramCriteria ? paramCriteria : []}}`)
				.then(res => {
					let oldData = data
					oldData = [...oldData, ...res.data]
					setDate(oldData);
					setCounter(counter + 1)
					setLink(props.url + `index.php/Search_engine/search/${offset * (counter + 1)}/${offset}?lang=` + lang + "&api_key=555b2feb468c0360cd705e6cffd28d9d")
					if(res.data.length === offset) {
						setMore(true)
						setHistoryOffset(offset * (counter + 1))
					}
					setScrollHeight(document.documentElement.scrollHeight - window.innerHeight)
					let nullCounter = 0
					for (const item in res.data) {
						if(res.data[item].image === null) {
							nullCounter++
						}
					}
					setImagesNull(nullCounter)
				})
			}
	},[counter, data, lang, link, more, offset, paramKeyword, props.url, scrollPercent, paramType, imagesNull, paramCriteria])
	
	useEffect(() => {
		let calc = parseInt((scrollPosition/scrollHeight * 100).toFixed(0))
		setScrollPercent(calc)
	},[scrollHeight, scrollPosition])

	useEffect(() => {
		let header = document.getElementById("header")
		header.classList.remove(headerStyles.header)
	},[])

	useEffect(() => {
		if (location !== prevLocation) {
			setPrevLocation(location)
			window.location.reload(false);
		}
	},[location, prevLocation])

	return (
		<>
			<Loader/>
			{data ? (
				<div className={styles.wrapper}>
					<div className={`${styles.searchWrapper} ${styles.advancedSearchButton}`}>
						<AdvancedInputs type={paramType} keyword={paramKeyword} criteria={paramCriteria}/>
					</div>
					<List listTitlesType={false} data={data} main={props.main} isSearch={true} advSearch={true} searchParam={paramKeyword} advancedSearch={true} lang={lang} historyOffset={historyOffset + offset}/>
				</div>
			) : null}
		</>
	);
}

export default AdvancedSearchWrapper;

