import React, { useEffect, useState } from 'react';

import styles from './modalInput.module.scss'
import translation from "../Helpers/translation";

function ModalInput(props) {

    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState(null)


    const slideDownUp = () => {
        /** Slide down. */
        const container = document.querySelectorAll(`[data-name~=${props.title}]`)[0].children[2];

        if (!container.classList.contains(styles.modalWrapperOpen)) {
            container.classList.add(styles.modalWrapperOpen)
            container.style.height = "auto"

            let height = container.clientHeight + "px"

            container.style.height = "0px"

            setTimeout(() => {
                container.style.height = height
            }, 1)
            /** Slide up. */
        } else {
            container.style.height = container.clientHeight + "px"
            container.style.height = "0px"

            setTimeout(() => {
                container.classList.remove(styles.modalWrapperOpen)
            }, 401)
        }
    }

    useEffect(() => {
        for (const dataKey in props.data) {
            if (parseInt(props.value) === props.data[dataKey].value) {
                setTitle(translation.typeDescription[props.data[dataKey].name])
            }
        }
    },[props.data, props.value])

    return (
        <div
            className={`${styles.inputWrapper} ${open ? styles.inputWrapperOpen : ''} ${title ? styles.inputWrapperSeted : ''} ${props.hidden ? styles.inputWrapperHidden : ''}`}
            onClick={(event) => {
                setOpen(!open);
                slideDownUp(event)
            }}
            data-name={props.title}
        >
            <div className={styles.titlesWrapper}>
                <span className={styles.title}>{translation.advancedSearch.titles[props.title]}</span>
                <span className={styles.value}>{title}</span>
            </div>

            <div className={styles.arrow}/>
            <div className={styles.modalWrapper}>
                {props.data.map((item, index) => {
                    return (
                        <div key={index} className={styles.item} data-value={item.value}
                             onClick={(event) => {
                             props.changeValues(props.title, event.target.dataset.value);
                             setTitle(event.target.parentElement.parentElement.getElementsByClassName(styles.value)[0].innerHTML = event.target.innerHTML );}}
                        >
                            {translation.typeDescription[item.name]}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ModalInput;