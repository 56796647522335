import React from 'react';
import styles from './loader.module.scss'
import translation from "../Helpers/translation";

export function Loader(props) {

    return (
        <div className={styles.loader} id={"loader"}>
            <div className={styles.ldsDualRing}/>
            {props.offlineDisable && !navigator.onLine? (
                <span className={styles.text}>{translation.serviceWorkerFeatureDisable}</span>
            ) : null}
            <span className={styles.text} id={"loaderOffline"}/>
        </div>
    );
}

export function FadeOut(name) {

    const el = document.getElementById(name)
    setTimeout(() => {
        //document.getElementById("loader").style.display = "none";
        let opacity = 1;

        el.style.opacity = "1";
        el.style.filter = '';

        let last = +new Date();
        let tick = function() {
            opacity -= (new Date() - last) / 400;
            el.style.opacity = opacity;
            el.style.filter = 'alpha(opacity=' + (100 * opacity)|1 + ')';

            last = +new Date();

            if (opacity > 0) {
                (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
            } else  {
                el.style.display = "none"
            }
        };

        tick();
    },100)
}