import React, { useEffect,useState } from 'react';
import {Link} from "react-router-dom";

import translation from "../Helpers/translation";

import styles from "./menu.module.scss"
import nifc_pl from "../../assets/image/footer/logo_white.png";

function Menu() {



    const [lang,setLang ] = useState(window.location.href.split('/')[3]);

    useEffect(() => {
        setLang(window.location.href.split('/')[3])
    },[])

    const changeLang = () => {
        const url = window.location.href.split('/')
        let newUrl = ""

        for( let i = 0; i < url.length; i++) {
            if (i === 3) {
                if(url[i] === "pl") {
                    newUrl = newUrl + "en/"
                } else {
                    newUrl = newUrl + "pl/"
                }
            } else if (i === url.length - 1){
                newUrl = newUrl + url[i]
            } else {
                newUrl = newUrl + url[i] + "/"
            }
        }
        window.location.href = newUrl;
    }

    const showHideMenu = (e) => {
        let menu_transform = document.getElementById("menu").style.transform;
        if (menu_transform === "none") {
            document.getElementById("menu").style.transform = "translate(-101%, 0)";
            document.getElementById("burger").checked = false;
        } else {
            document.getElementById("menu").style.transform = "none";
            document.getElementById("burger").checked = true;
        }

        if(e.target.href) {
            window.scroll(0,0)
        }
    }

    const unsetOffset = () => {
        sessionStorage.removeItem("offset")
    }

    return (
        <nav className={`${styles.navigation} ${styles.navigationGalleryAndObject}`}>
            <div className={styles.menuToggle}>
                <input type={"checkbox"} onClick={showHideMenu} id={"burger"}/>
                <span/>
                <span/>
                <span/>
                <ul className={styles.menu} id={"menu"}>
                    <li>
                        <Link to={`/${lang}`} onClick={(event)=>{showHideMenu(event); unsetOffset();}}>{translation.menu.collections}</Link>
                    </li>
                    <li>
                        <Link to={`/${lang}/o-projekcie`} onClick={(event)=>{showHideMenu(event); unsetOffset();}}>{translation.menu.about}</Link>
                    </li>
                    <li>
                        <Link to={`/${lang}/polityka-prywatnosci`} onClick={(event)=>{showHideMenu(event); unsetOffset();}}>{translation.menu.policy}</Link>
                    </li>
                    <li>
                        <Link to={`/${lang}/deklaracja-dostepnosci`} onClick={(event)=>{showHideMenu(event); unsetOffset();}}>{translation.menu.accessibility}</Link>
                    </li>
                    <li>
                        <Link to={`/${lang}/kontakt`} onClick={(event)=>{showHideMenu(event); unsetOffset();}}>{translation.menu.contact}</Link>
                    </li>
                    <li>
                        <div className={styles.langButton} onClick={changeLang}>{translation.menu.language}</div>
                    </li>
                    <li className={styles.nifc}>
                        <a href={"https://nifc.pl/"}>{translation.menu.nifc}</a>
                    </li>
                    <li><img src={nifc_pl} alt=""/></li>
                </ul>
                <div className={styles.menuHider}/>
            </div>
        </nav>
    );
}

export default Menu;